import React, {Component} from 'react';
import {Modal, Button, Row, Col, Form} from 'react-bootstrap';
import axios from 'axios';
import {Progress} from 'reactstrap';

class AddCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile1: null,
      selectedFile2: null,
      image2: '',
      image1: '',
    };
  }

  handleSubmit = event => {
    let image1 = this.state.image1;
    let image2 = this.state.image2;
    fetch(`${process.env.REACT_APP_API_URL}/categories/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        //name: event.target.name.value,
        type: event.target.type.value,
        label: event.target.type.value,
        desc: event.target.desc.value,
        // priority: event.target.priority.value,
        icon: image1,
        cover: image2,
      }),
    }).then(res => res.json());
    event.preventDefault();
    alert('You are submitting' + window.location.reload());
  };

  fileSelectedHandler1 = event => {
    this.setState({
      selectedFile1: event.target.files[0],
    });
  };

  fileUploadIcon = () => {
    const fd1 = new FormData();
    fd1.append('file', this.state.selectedFile1, this.state.selectedFile1.name);
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/upload`, fd1, {
        onUploadProgress: ProgressEvent => {
          this.setState({
            loadedIcon: (ProgressEvent.loaded / ProgressEvent.total) * 100,
          });
        },
      })
      .then(res => {
        this.setState({image1: res.data.path});
      });
  };

  fileSelectedHandler2 = event => {
    this.setState({
      selectedFile2: event.target.files[0],
    });
  };

  fileUploadCover = () => {
    const fd2 = new FormData();
    fd2.append('file', this.state.selectedFile2, this.state.selectedFile2.name);
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/upload`, fd2, {
        onUploadProgress: ProgressEvent => {
          this.setState({
            loadedCover: (ProgressEvent.loaded / ProgressEvent.total) * 100,
          });
        },
      })
      .then(res => {
        this.setState({image2: res.data.path});
      });
  };

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Categories
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <Row>
              <Col sm={12}>
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    {/* <Col sm={6}>
                      <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="name"
                        />
                      </Form.Group>
                    </Col> */}
                    <Col sm={6}>
                      <Form.Group controlId="label">
                        <Form.Label>Label</Form.Label>
                        <Form.Control
                          type="text"
                          name="label"
                          placeholder="label"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId="type">
                        <Form.Label>Type</Form.Label>
                        <Form.Control as="select" name="type">
                          <option>lwm</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    {/* <Col sm={6}>
                      <Form.Group controlId="priority">
                        <Form.Label>Priority</Form.Label>
                        <Form.Control
                          type="number"
                          name="priority"
                          placeholder="priority"
                        />
                      </Form.Group>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <Form.Group controlId="desc">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          type="text"
                          name="desc"
                          placeholder="description"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Icon Upload</Form.Label>
                        <Form.Control
                          type="file"
                          name={this.state.image1}
                          onChange={this.fileSelectedHandler1}
                        />
                        <Button onClick={this.fileUploadIcon}>
                          Upload Icon Image
                        </Button>
                        <Progress
                          max="100"
                          color="success"
                          value={this.state.loaded}
                        >
                          {Math.round(this.state.loadedIcon, 2)}%
                        </Progress>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Cover Upload</Form.Label>
                        <Form.Control
                          type="file"
                          name={this.state.image2}
                          onChange={this.fileSelectedHandler2}
                        />
                        <Button onClick={this.fileUploadCover}>
                          Upload Cover Image
                        </Button>
                        <Progress
                          max="100"
                          color="success"
                          value={this.state.loaded}
                        >
                          {Math.round(this.state.loadedCover, 2)}%
                        </Progress>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={8}></Col>
                    <Col sm={4}>
                      <Form.Group>
                        <Button type="submit">Add Categories</Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddCategories;
