import React from "react";

class Thesis extends React.Component {
  constructor(props) {
    super(props);
    this.state = { textContent: "", textCaption: "" };
  }

  onChange = (evt) => {
    this.setState({
      textContent: evt.editor.getData(),
    });
  };

  onPChange = (evt) => {
    this.setState({
      textCaption: evt.editor.getData(),
    });
  };

  render() {
    console.log(this.state);
    return (
      <div className="col-sm-12 no-padding">
        <div className="row">
          <div className="mar-b-16 col-sm-6 no-l-padding">
            <h6>Discipline</h6>
            <input
              type="text"
              name="tDiscipline"
              placeholder="Enter Discipline"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
          <div className="mar-b-16 col-sm-6">
            <h6>UG/PG</h6>
            <input
              type="text"
              name="tUgPg"
              placeholder="Enter Education"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
        </div>
        <div className="row">
          <div className="mar-b-16 col-sm-6">
            <h6>Institute</h6>
            <input
              type="text"
              name="tInstitute"
              placeholder="Enter Institute"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
          <div className="mar-b-16 col-sm-6">
            <h6>Year of Publishing</h6>
            <input
              type="text"
              name="tYear"
              placeholder="Enter Year"
              style={{ width: "100%", height: 48 }}
              maxlength="4"
            />
          </div>
        </div>
        <div className="row">
          <div className="mar-b-16 col-sm-12">
            <h6>Abstract</h6>
            <input
              type="textarea"
              name="tAbstract"
              placeholder="Enter Doc About"
              style={{ width: "100%", height: 48 }}
              maxlength="1300"
            />
          </div>
        </div>
      </div>
    );
  }
}
export default Thesis;
