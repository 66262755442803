import React, { Component } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";

import Select from "react-select";
import axios from "axios";
import { Progress } from "reactstrap";
import Books from "./types/books";
import Films from "./types/films";
import AudioVideo from "./types/audiovideo";
import ArtWorks from "./types/artworks";
import People from "./types/people";
import Documents from "./types/Documents";
import Thesis from "./types/thesis";
import Organisation from "./types/organisation";
import Articles from "./types/articles";
import Projects from "./types/projects";
import "../../index.css";

const options = [
  { value: "books", label: "Books" },
  { value: "films", label: "Films" },
  { value: "audiovideo", label: "Audio Video" },
  { value: "artworks", label: "Art Works" },
  { value: "documents", label: "Documents" },
  { value: "people", label: "People" },
  { value: "thesis", label: "Thesis" },
  { value: "organisation", label: "Organisation" },
  { value: "articles", label: "Articles" },
  { value: "projects", label: "Projects" },
];

class AddResourceData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      image: "",
      subType: "",
      selectedOption: "books",
    };
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    console.log("Option selected: ", selectedOption);
  };

  handleSubmit = (event) => {
    let image = this.state.image;
    if (this.state.selectedOption.value === "books") {
      fetch(`${process.env.REACT_APP_API_URL}/stories/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          //name: event.target.name.value,
          type: event.target.type.value,
          subType: this.state.selectedOption.value,
          label: event.target.label.value,
          desc: event.target.booksAbstract.value,
          author: event.target.booksAuthor.value,
          publisher: event.target.booksPublisher.value,
          isbn: event.target.booksISBN.value,
          year: event.target.booksYear.value,
          link: event.target.link.value,
          cover: image,
        }),
      }).then((res) => res.json());
      event.preventDefault();
      window.confirm(
        '"You are Adding a resource data to LWM Platform, Please confirm!"'
      );
      window.location.reload();
    }
    if (this.state.selectedOption.value === "films") {
      fetch(`${process.env.REACT_APP_API_URL}/stories/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          //name: event.target.name.value,
          type: event.target.type.value,
          subType: this.state.selectedOption.value,
          label: event.target.label.value,
          desc: event.target.filmsAbstract.value,
          director: event.target.filmsDirector.value,
          producer: event.target.filmsProducer.value,
          year: event.target.filmsYear.value,
          link: event.target.link.value,
          cover: image,
        }),
      }).then((res) => res.json());
      event.preventDefault();
      window.confirm(
        '"You are Adding a resource data to LWM Platform, Please confirm!"'
      );
      window.location.reload();
    }
    if (this.state.selectedOption.value === "audiovideo") {
      fetch(`${process.env.REACT_APP_API_URL}/stories/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          //name: event.target.name.value,
          type: event.target.type.value,
          subType: this.state.selectedOption.value,
          label: event.target.label.value,
          desc: event.target.avAbstract.value,
          customType: event.target.avType.value,
          creator: event.target.avCreator.value,
          supporter: event.target.avSupporter.value,
          publisher: event.target.avPublisher.value,
          link: event.target.link.value,
          cover: image,
        }),
      }).then((res) => res.json());
      event.preventDefault();
      window.confirm(
        '"You are Adding a resource data to LWM Platform, Please confirm!"'
      );
      window.location.reload();
    }
    if (this.state.selectedOption.value === "artworks") {
      fetch(`${process.env.REACT_APP_API_URL}/stories/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          //name: event.target.name.value,
          type: event.target.type.value,
          subType: this.state.selectedOption.value,
          label: event.target.label.value,
          desc: event.target.awAbstract.value,
          artist: event.target.awArtist.value,
          facilitator: event.target.awfacilitator.value,
          curator: event.target.awCurator.value,
          gallery: event.target.awGallery.value,
          exhibition: event.target.awExhibition.value,
          link: event.target.link.value,
          cover: image,
        }),
      }).then((res) => res.json());
      event.preventDefault();
      window.confirm(
        '"You are Adding a resource data to LWM Platform, Please confirm!"'
      );
      window.location.reload();
    }
    if (this.state.selectedOption.value === "documents") {
      fetch(`${process.env.REACT_APP_API_URL}/stories/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          //name: event.target.name.value,
          type: event.target.type.value,
          subType: this.state.selectedOption.value,
          label: event.target.label.value,
          desc: event.target.docAbstract.value,
          customType: event.target.docType.value,
          author: event.target.docAuthor.value,
          year: event.target.docYear.value,
          publisher: event.target.docPublisher.value,
          link: event.target.link.value,
          cover: image,
        }),
      }).then((res) => res.json());
      event.preventDefault();
      window.confirm(
        '"You are Adding a resource data to LWM Platform, Please confirm!"'
      );
      window.location.reload();
    }
    if (this.state.selectedOption.value === "people") {
      fetch(`${process.env.REACT_APP_API_URL}/stories/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          //name: event.target.name.value,
          type: event.target.type.value,
          subType: this.state.selectedOption.value,
          label: event.target.label.value,
          desc: event.target.pplAbstract.value,
          associations: event.target.pplAssociation.value,
          expertise: event.target.pplExpertise.value,
          location: event.target.pplLocation.value,
          link: event.target.link.value,
          cover: image,
        }),
      }).then((res) => res.json());
      event.preventDefault();
      window.confirm(
        '"You are Adding a resource data to LWM Platform, Please confirm!"'
      );
      window.location.reload();
    }
    if (this.state.selectedOption.value === "thesis") {
      fetch(`${process.env.REACT_APP_API_URL}/stories/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          //name: event.target.name.value,
          type: event.target.type.value,
          subType: this.state.selectedOption.value,
          label: event.target.label.value,
          desc: event.target.tAbstract.value,
          discipline: event.target.tDiscipline.value,
          ugpg: event.target.tUgPg.value,
          institute: event.target.tInstitute.value,
          year: event.target.tYear.value,
          link: event.target.link.value,
          cover: image,
        }),
      }).then((res) => res.json());
      event.preventDefault();
      window.confirm(
        '"You are Adding a resource data to LWM Platform, Please confirm!"'
      );
      window.location.reload();
    }

    if (this.state.selectedOption.value === "organisation") {
      fetch(`${process.env.REACT_APP_API_URL}/stories/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          //name: event.target.name.value,
          type: event.target.type.value,
          subType: this.state.selectedOption.value,
          label: event.target.label.value,
          desc: event.target.oAbstract.value,
          expertise: event.target.oExpertise.value,
          founder: event.target.oFounder.value,
          location: event.target.oLocation.value,
          year: event.target.oYear.value,
          link: event.target.link.value,
          cover: image,
        }),
      }).then((res) => res.json());
      event.preventDefault();
      window.confirm(
        '"You are Adding a resource data to LWM Platform, Please confirm!"'
      );
      window.location.reload();
    }

    if (this.state.selectedOption.value === "articles") {
      fetch(`${process.env.REACT_APP_API_URL}/stories/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          //name: event.target.name.value,
          type: event.target.type.value,
          subType: this.state.selectedOption.value,
          label: event.target.label.value,
          desc: event.target.aAbstract.value,
          customType: event.target.aType.value,
          author: event.target.aAuthor.value,
          publisher: event.target.aPublisher.value,
          year: event.target.aYear.value,
          link: event.target.link.value,
          cover: image,
        }),
      }).then((res) => res.json());
      event.preventDefault();
      window.confirm(
        '"You are Adding a resource data to LWM Platform, Please confirm!"'
      );
      window.location.reload();
    }

    if (this.state.selectedOption.value === "projects") {
      fetch(`${process.env.REACT_APP_API_URL}/stories/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          //name: event.target.name.value,
          type: event.target.type.value,
          subType: this.state.selectedOption.value,
          label: event.target.label.value,
          desc: event.target.pAbstract.value,
          domain: event.target.pDomain.value,
          collaborator: event.target.pCollaborator.value,
          Facilitator: event.target.pfacilitator.value,
          owner: event.target.pOwner.value,
          participants: event.target.pParticipants.value,
          link: event.target.link.value,
          cover: image,
        }),
      }).then((res) => res.json());
      event.preventDefault();
      window.confirm(
        '"You are Adding a resource data to LWM Platform, Please confirm!"'
      );
      window.location.reload();
    }
  };

  fileSelectedHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  fileUploadHandler = () => {
    const fd = new FormData();
    fd.append("file", this.state.selectedFile, this.state.selectedFile.name);
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/upload`, fd, {
        onUploadProgress: (ProgressEvent) => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
          });
        },
      })
      .then((res) => {
        this.setState({ image: res.data.path });
      });
  };

  render() {
    const { selectedOption } = this.state;
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Resource Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <Row>
              <Col sm={12}>
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId="type">
                        <Form.Label>Type</Form.Label>
                        <Form.Control as="select" name="type">
                          <option>resources</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="subType">
                        <Form.Label>SubType</Form.Label>
                        <div>
                          <Select
                            value={selectedOption}
                            onChange={this.handleChange}
                            options={options}
                            placeholder="Select Type"
                            isSearchable={options}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <Form.Group controlId="label">
                        <Form.Label>Label</Form.Label>
                        <Form.Control
                          type="text"
                          name="label"
                          placeholder="label"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div>
                    {selectedOption && selectedOption.value === "books" ? (
                      <Books image={this.handleImage} />
                    ) : selectedOption && selectedOption.value === "films" ? (
                      <Films image={this.handleImage} />
                    ) : selectedOption &&
                      selectedOption.value === "audiovideo" ? (
                      <AudioVideo image={this.handleImage} />
                    ) : selectedOption &&
                      selectedOption.value === "artworks" ? (
                      <ArtWorks image={this.handleImage} />
                    ) : selectedOption &&
                      selectedOption.value === "documents" ? (
                      <Documents image={this.handleImage} />
                    ) : selectedOption && selectedOption.value === "people" ? (
                      <People image={this.handleImage} />
                    ) : selectedOption && selectedOption.value === "thesis" ? (
                      <Thesis image={this.handleImage} />
                    ) : selectedOption &&
                      selectedOption.value === "organisation" ? (
                      <Organisation image={this.handleImage} />
                    ) : selectedOption &&
                      selectedOption.value === "articles" ? (
                      <Articles image={this.handleImage} />
                    ) : selectedOption &&
                      selectedOption.value === "projects" ? (
                      <Projects image={this.handleImage} />
                    ) : null}
                  </div>
                  <Row>
                    <Col sm={12}>
                      <Form.Group controlId="link">
                        <Form.Label>link </Form.Label>
                        <Form.Control
                          type="text"
                          name="link"
                          placeholder="link"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Cover Upload</Form.Label>
                        <Form.Control
                          type="file"
                          name={this.state.image}
                          onChange={this.fileSelectedHandler}
                        />
                        <Button onClick={this.fileUploadHandler}>
                          Upload Cover Image
                        </Button>
                        <Progress
                          max="100"
                          color="success"
                          value={this.state.loaded}
                        >
                          {Math.round(this.state.loaded, 2)}%
                        </Progress>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={8}></Col>
                    <Col sm={4} className="text-align-r">
                      <Form.Group>
                        <Button type="submit">Add Resource Data</Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddResourceData;
