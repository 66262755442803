/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import { Progress } from "reactstrap";
import "../../index.css";
import CKEditor from "react-ckeditor-component";

const options = [
  { value: "text", label: "Text" },
  { value: "img", label: "Image" },
  { value: "video", label: "Video" },
];

class EditStoryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.order,
      image: "",
      selectedOption: props.stype,
      order: props.data,
      enforceFocus: false,
      selectedFile: null,
    };
    this.onChange = this.onChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(props) {
    if (props.show !== false) {
      this.setState(
        {
          id: props.sorderid,
          order: props.sorderid,
          sid: props.sid,
          type: props.stype,
          stitle: props.stitle,
          scaption: props.scaption,
          scontent: props.scontent,
          url: props.surl,
          image: props.surl,
        },
        () => {}
      );
    }
  }

  onChange = (e) => {
    this.set({ [e.target.name]: e.target.value });
  };

  onTChange = (evt) => {
    this.setState({
      scontent: evt.editor.getData(),
    });
  };

  onCPChange = (evt) => {
    this.setState({
      scaption: evt.editor.getData(),
    });
  };

  handleImage = (image) => {
    this.setState({
      image: image,
    });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const token = localStorage.jwtToken ? localStorage.jwtToken : '';
    if (this.state.type === "text") {
      var r = window.confirm(
        "You are editing a story data to LWM Platform, Please confirm!"
      );
      if (r === true) {
        fetch(
          `${process.env.REACT_APP_API_URL}/stories/${this.props.sid}/block/${this.props.sorderid}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              'x-access-token': token,
            },
            body: JSON.stringify({
              order: this.state.order,
              type: this.state.type,
              content: this.state.scontent,
              caption: this.state.scaption,
              title: event.target.title.value,
            }),
          }
        ).then((res) =>
          res.json().then((responsejson) => {
            setTimeout(function () {
              window.location.reload();
            }, 3000);
          })
        );
      } else {
      }
    } else if (this.state.type === "img") {
      var r = window.confirm(
        "You are editing a story data to LWM Platform, Please confirm!"
      );
      if (r === true) {
        fetch(
          `${process.env.REACT_APP_API_URL}/stories/${this.props.sid}/block/${this.props.sorderid}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              'x-access-token': token,
            },
            body: JSON.stringify({
              order: this.state.order,
              type: this.state.type,
              url: this.state.image,
              caption: this.state.scaption,
              title: event.target.title.value,
            }),
          }
        ).then((res) =>
          res.json().then((responsejson) => {
            setTimeout(function () {
              window.location.reload();
            }, 3000);
          })
        );
      } else {
      }
    } else if (this.state.type === "video") {
      let videoTempUrl = event.target.videourl.value;
      videoTempUrl = videoTempUrl.replace("/watch?v=", "/embed/");
      var r = window.confirm(
        "You are editing a story data to LWM Platform, Please confirm!"
      );
      if (r === true) {
        fetch(
          `${process.env.REACT_APP_API_URL}/stories/${this.props.sid}/block/${this.props.sorderid}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              'x-access-token': token,
            },
            body: JSON.stringify({
              order: this.state.order,
              type: this.state.type,
              caption: this.state.scaption,
              videourl: videoTempUrl,
              title: event.target.title.value,
            }),
          }
        ).then((res) =>
          res.json().then((responsejson) => {
            setTimeout(function () {
              window.location.reload();
            }, 3000);
          })
        );
      } else {
      }
    } else if (this.state.type === "3d") {
      var r = window.confirm(
        "You are editing a story data to LWM Platform, Please confirm!"
      );
      if (r === true) {
        fetch(
          `${process.env.REACT_APP_API_URL}/stories/${this.props.sid}/block/${this.props.sorderid}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              'x-access-token': token,
            },
            body: JSON.stringify({
              order: this.state.order,
              type: this.state.type,
              caption: this.state.scaption,
              threed: event.target.threed.value,
              title: event.target.title.value,
            }),
          }
        ).then((res) =>
          res.json().then((responsejson) => {
            setTimeout(function () {
              window.location.reload();
            }, 3000);
          })
        );
      } else {
      }
    }
    else if (this.state.type === "audio") {
      var r = window.confirm(
        "You are editing a story data to LWM Platform, Please confirm!"
      );
      if (r === true) {
        fetch(
          `${process.env.REACT_APP_API_URL}/stories/${this.props.sid}/block/${this.props.sorderid}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              'x-access-token': token,
            },
            body: JSON.stringify({
              order: this.state.order,
              type: this.state.type,
              caption: this.state.scaption,
              audio: event.target.audio.value,
              title: event.target.title.value,
            }),
          }
        ).then((res) =>
          res.json().then((responsejson) => {
            setTimeout(function () {
              window.location.reload();
            }, 3000);
          })
        );
      } else {
      }
    } else if (this.state.type === "vr") {
      var r = window.confirm(
        "You are editing a story data to LWM Platform, Please confirm!"
      );
      if (r === true) {
        fetch(
          `${process.env.REACT_APP_API_URL}/stories/${this.props.sid}/block/${this.props.sorderid}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              'x-access-token': token,
            },
            body: JSON.stringify({
              order: this.state.order,
              type: this.state.type,
              caption: this.state.scaption,
              vr: event.target.vr.value,
              title: event.target.title.value,
            }),
          }
        ).then((res) =>
          res.json().then((responsejson) => {
            setTimeout(function () {
              window.location.reload();
            }, 3000);
          })
        );
      } else {
      }
    }
  };

  fileSelectedHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  fileUploadHandler = () => {
    const fd1 = new FormData();
    fd1.append("file", this.state.selectedFile);
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/upload`, fd1, {
        onUploadProgress: (ProgressEvent) => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
          });
        },
      })
      .then((res) => {
        // this.props.image(res.data.path);
        this.setState({ image: res.data.path });
      });
  };

  imageUrlChangeHandler = (event) => {
    const imgUrl = event.target.value;
    if( imgUrl && imgUrl !== '' ) {
      this.setState({ image: imgUrl });
    }
  }

  render() {
    const { selectedOption } = this.state;
    return (
      <Modal
        {...this.props}
        size="lg"
        enforceFocus={this.state.enforceFocus}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Story Data
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container">
            <Row>
              <Col sm={12}>
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId="id">
                        <Form.Label>Id</Form.Label>
                        <Form.Control
                          type="number"
                          name="id"
                          required
                          disabled
                          placeholder="id"
                          defaultValue={this.props.sid}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="type">
                        <Form.Label>Type</Form.Label>
                        <div>
                          <input
                            type="text"
                            name="type"
                            defaultValue={this.state.type}
                            disabled
                            placeholder="title"
                            style={{ width: "58%" }}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <Form.Group controlId="title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="text"
                          name="title"
                          placeholder="enter title here"
                          defaultValue={this.props.stitle}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <div>
                        {this.state.type && this.state.type === "img" ? (
                          <div>
                            <h6>Image Upload</h6>
                            <div>
                              <input
                                type="file"
                                id="dataImg"
                                name={this.state.image}
                                onChange={this.fileSelectedHandler}
                                placeholder="image"
                              />
                            </div>
                            <div style={{ margin: 10 }}>
                              <Progress
                                max="100"
                                color="success"
                                value={this.state.loaded}
                              >
                                {Math.round(this.state.loaded, 2)}%
                              </Progress>
                            </div>
                            <Button onClick={this.fileUploadHandler}>
                              Upload Image
                            </Button>
                          <br>
                          </br>
                          OR
                          <br></br>
                           <div>
                            <label>Add Image URL</label>
                            <input
                              type="text"
                              placeholder="enter url here"
                              defaultValue={this.props.image}
                              name="imageUrl"
                              onChange={this.imageUrlChangeHandler}
                            />
                          </div>
                        </div>
                        ) : this.state.type && this.state.type === "text" ? (
                          <div>
                            <h6>Enter your content here</h6>
                            <CKEditor
                              activeClass="p10"
                              name="content"
                              defaultValue={this.props.scontent}
                              content={this.state.scontent}
                              events={{
                                change: this.onTChange,
                              }}
                            />
                          </div>
                        ) : this.state.type && this.state.type === "video" ? (
                          <div>
                            <label>Add Video URL</label>
                            <input
                              type="text"
                              required
                              placeholder="enter url here"
                              defaultValue={this.props.url}
                              name="videourl"
                            />
                          </div>
                        ) : this.state.type && this.state.type === "3d" ? (
                          <div>
                            <h6>Enter Your Embed Code</h6>
                            <textarea
                              type="text"
                              rows="6"
                              cols="50"
                              required
                              placeholder="enter embed url from sketchfeb.com"
                              defaultValue={this.props.threed}
                              name="threed"
                            />
                          </div>
                        ) : this.state.type && this.state.type === "audio" ? (
                          <div>
                            <h6>Enter Your Embed Code</h6>
                            <textarea
                              type="text"
                              rows="6"
                              cols="50"
                              required
                              placeholder="enter embed url from soundcloud"
                              defaultValue={this.props.audio}
                              name="audio"
                            />
                          </div>
                        ) : this.state.type && this.state.type === "vr" ? (
                          <div>
                            <h6>Enter Your Embed Code</h6>
                            <textarea
                              type="text"
                              rows="6"
                              cols="50"
                              required
                              placeholder="enter embed url from Pedals Up Team"
                              defaultValue={this.props.vr}
                              name="vr"
                            />
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <div>
                        <h6>Enter your caption here</h6>
                        <CKEditor
                          activeClass="p10"
                          name="caption"
                          content={this.state.scaption}
                          events={{
                            change: this.onCPChange,
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={8}></Col>
                    <Col sm={4} className="text-align-r">
                      <Form.Group>
                        <Button type="submit">Edit Story Data</Button>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group>
                    <p>{this.state.content}</p>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditStoryData;
