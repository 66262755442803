import React from "react";

class Articles extends React.Component {
    constructor(props) {
      super(props);
      this.state = { textContent: "",textCaption: "" };
  
    }
  
    onChange = (evt) => {
      this.setState( {
        textContent: evt.editor.getData()
      } );
    }
  
    onPChange = (evt) => {
      this.setState( {
        textCaption: evt.editor.getData()
      } );
    }
  
  
    render() {
      console.log(this.state);
      return (
        <div className="col-sm-12 no-padding">
          <div className="row">
            <div className="mar-b-16 col-sm-6 no-l-padding">
                <h6>Type</h6>
                <input
                type="text"
                name="aType"
                placeholder="Enter Article Type"
                style={{ width: "100%", height: 48 }}
                maxlength="100"
                />
            </div>
            <div className="mar-b-16 col-sm-6">
                <h6>Author</h6>
                <input
                type="text"
                name="aAuthor"
                placeholder="Enter Author"
                style={{ width: "100%", height: 48 }}
                maxlength="100"
                />
            </div>
          </div>
          <div className="row">
            <div className="mar-b-16 col-sm-6">
                <h6>Publisher</h6>
                <input
                type="text"
                name="aPublisher"
                placeholder="Enter Publisher"
                style={{ width: "100%", height: 48 }}
                maxlength="100"
                />
            </div>
            <div className="mar-b-16 col-sm-6">
                <h6>Year of Publishing</h6>
                <input
                type="text"
                name="aYear"
                placeholder="Enter Year"
                style={{ width: "100%", height: 48 }}
                maxlength="4"
                />
            </div>
            
          </div>
          <div className="row">
            <div className="mar-b-16 col-sm-12">
                <h6>Abstract</h6>
                <input
                type="textarea"
                name="aAbstract"
                placeholder="Enter Article Abstract"
                style={{ width: "100%", height: 48 }}
                maxlength="1300"
                />
            </div>
          </div>
        </div>
      );
    }
  }
  export default Articles;