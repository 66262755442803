import React, {Component} from 'react';
import {Modal, Button, Row, Col, Form} from 'react-bootstrap';
import axios from 'axios';
import {Progress} from 'reactstrap';
import '../../index.css';

class AddResourceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      image: '',
    };
  }

  handleSubmit = event => {
    let image = this.state.image;
    fetch(`${process.env.REACT_APP_API_URL}/resources/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        // priority: event.target.priority.value,
        name: event.target.name.value,
        type: event.target.type.value,
        label: event.target.label.value,    
        icon: image,
            }),
    }).then(res => res.json());
    event.preventDefault();
    window.confirm(
      '"You are Adding a resource to LWM Platform, Please confirm!"'
    );
    window.location.reload();
  };

  fileSelectedHandler = event => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  fileUploadHandler = () => {
    const fd = new FormData();
    fd.append('file', this.state.selectedFile, this.state.selectedFile.name);
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/upload`, fd, {
        onUploadProgress: ProgressEvent => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
          });
        },
      })
      .then(res => {
        this.setState({image: res.data.path});
      });
  };

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Resources
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <Row>
              <Col sm={12}>
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    {/* <Col sm={6}>
                      <Form.Group controlId="priority">
                        <Form.Label>Priority</Form.Label>
                        <Form.Control
                          type="number"
                          name="priority"
                          placeholder="id"
                        />
                      </Form.Group>
                    </Col> */}
                    <Col sm={6}>
                      <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="name"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId="type">
                        <Form.Label>Type</Form.Label>
                        <Form.Control as="select" name="type">
                          <option>lwm</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="label">
                        <Form.Label>Label</Form.Label>
                        <Form.Control
                          type="text"
                          name="label"
                          placeholder="label"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <Form.Group>
                        <Form.Label>Icon Upload</Form.Label>
                        <Form.Control
                          type="file"
                          name={this.state.image}
                          onChange={this.fileSelectedHandler}
                        />
                        <Button onClick={this.fileUploadHandler}>
                          Upload Icon Image
                        </Button>
                        <Progress
                          max="100"
                          color="success"
                          value={this.state.loaded}
                        >
                          {Math.round(this.state.loaded, 2)}%
                        </Progress>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={8}></Col>
                    <Col sm={4} className="text-align-r">
                      <Form.Group>
                        <Button type="submit">Add Resources</Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddResourceModal;
