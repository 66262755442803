import React, {Component} from 'react';
import {Modal, Button, Row, Col, Form} from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import {Progress} from 'reactstrap';

const options = [
  {value: 'text', label: 'Text'},
  {value: 'img', label: 'Image'},
];

class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = {image: '', selectedFile: null};
  }

  fileSelectedHandler = event => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  fileUploadHandler = () => {
    const fd1 = new FormData();
    fd1.append('file', this.state.selectedFile);
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/upload`, fd1, {
        onUploadProgress: ProgressEvent => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
          });
        },
      })
      .then(res => {
        this.props.image(res.data.path);
        this.setState({image: res.data.path});
      });
  };

  render() {
    return (
      <div>
        <div>
          <label>image</label>
          <input
            type="file"
            id="dataImg"
            name={this.state.image}
            onChange={this.fileSelectedHandler}
            placeholder="image"
          />
          <Progress max="100" color="success" value={this.state.loaded}>
            {Math.round(this.state.loaded, 2)}%
          </Progress>
          <Button onClick={this.fileUploadHandler}>Upload Cover Image</Button>
        </div>
        <label>Caption</label>
        <input type="text" name="caption" placeholder="caption" />
      </div>
    );
  }
}

class Text extends React.Component {
  constructor(props) {
    super(props);
    this.state = {content: ''};
  }

  render() {
    return (
      <div>
        <label>Write You Content Here</label>
        <input type="text" name="cont" placeholder="text" required />
      </div>
    );
  }
}

class AddDataModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.data,
      image: '',
      selectedOption: null,
    };
    this.onChange = this.onChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onChange = e => {
    this.set({[e.target.name]: e.target.value});
  };

  handleImage = image => {
    this.setState({
      image: image,
    });
  };

  handleChange = selectedOption => {
    this.setState({selectedOption});
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.state.selectedOption.value === 'text') {
      fetch(
        `${process.env.REACT_APP_API_URL}/stories/block/${this.props.sId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: this.state.selectedOption.value,
            content: event.target.cont.value,
          }),
        }
      ).then(res => res.json());
    } else {
      fetch(
        `${process.env.REACT_APP_API_URL}/stories/block/${this.props.sId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: this.state.selectedOption.value,
            url: this.state.image,
            caption: event.target.caption.value,
          }),
        }
      ).then(res => res.json());
    }
  };

  render() {
    const {selectedOption} = this.state;
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Story Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <Row>
              <Col sm={6}>
                <Form onSubmit={this.handleSubmit}>
                  <Form.Group controlId="id">
                    <Form.Label>Id</Form.Label>
                    <Form.Control
                      type="number"
                      name="id"
                      required
                      disabled
                      placeholder="id"
                      defaultValue={this.props.sId}
                    />
                  </Form.Group>
                  <Form.Group controlId="type">
                    <Form.Label>Type</Form.Label>
                    <div>
                      <Select
                        value={selectedOption}
                        onChange={this.handleChange}
                        options={options}
                        placeholder="Select Type"
                        isSearchable={options}
                      />
                    </div>
                  </Form.Group>
                  <div>
                    {selectedOption && selectedOption.value === 'img' ? (
                      <Image image={this.handleImage} />
                    ) : selectedOption && selectedOption.value === 'text' ? (
                      <Text />
                    ) : null}
                  </div>
                  <Form.Group>
                    <Button type="submit">Add Data</Button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddDataModal;
