import React, {Component} from 'react';
import ReactTable from 'react-table-6';
import { Form} from 'react-bootstrap';
import 'react-table-6/react-table.css';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUpload, faEye} from '@fortawesome/free-solid-svg-icons';
import AddResourceData from './AddResourceData';
class ResourceAllList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      addModalShow: false,
     subtype:'',

    };
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/stories/resources`)
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            stories: result.stories,
          });
        },
        error => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  resourceModalClose = () => this.setState({resourceModalShow: false});
  updateResources(cData) {
    this.setState({
      selectedCategory: cData,
    });
  }

  publishStories(id) {
    fetch(`${process.env.REACT_APP_API_URL}/stories/publish/` + id, {
      method: 'PUT',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },  
    });
    window.confirm(
      '"You are Publishing the resource data to LWM Platform, Please confirm!"'
    );
    window.location.reload();
    
  }
  


  render() {
    const {error, isLoaded} = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="categories-container loader"></div>;
    } else {
      return (
        <div className="categories-container">
          <div className="title-container">
            <div className="title-left-container">
              <h3> Resource List </h3>
              <Form.Group controlId="subType" className="title-left-container-subType">
                        <Form.Control as="select" name="subType"
                        value={this.state.subtype} 
                        onChange={(event) => 
                       this.setState({subtype:event.target.value})}>
                         <option value =''>Subtype</option>
                          <option value='books'>books</option>
                          <option value='films'>films</option>
                          <option value='audiovideo'>audio-video</option>
                          <option value='artworks'>artworks</option>
                          <option value='documents'>Documents</option>
                          <option value='people'>people</option>
                          <option value='thesis'>thesis</option>
                          <option value='organisation'>organisation</option>
                          <option value='articles'>articles</option>
                          <option value='projects'>projects</option>
                        </Form.Control>
                      </Form.Group>
            </div>
          </div>

          <ReactTable
            loading={false}
            defaultPageSize={10}
            data={this.state.stories}
            filtered={[{ 
              id: 'subType',
              value: this.state.subtype,
            }]}

            columns={[
              {
                Header: 'Name',
                accessor: 'name',
                style: {whiteSpace: 'unset'},
              },
              {
                Header: 'SubType',
                accessor: 'subType',
                style: {whiteSpace: 'unset'},

              },
              {
                Header: 'Brief',
                accessor: 'brief',
                width: 150,
                style: {whiteSpace: 'unset',height: 100},
              },
              {
                Header: 'Descrption',
                accessor: 'desc',
                width: 150,
                style: {whiteSpace: 'unset',height: 100},
              },
              {
                Header: 'Author',
                accessor: 'author',
                style: {whiteSpace: 'unset'},
              },
              {
                Header: 'Publisher',
                accessor: 'publisher',
                style: {whiteSpace: 'unset'},
              },
              {
                Header: 'Icon',
                Cell: row => {
                  return (
                    <div>
                      <img
                        height={100}
                        alt={row.original.icon}
                        src={row.original.icon}
                      />
                    </div>
                  );
                },
              },

              {
                Header: 'cover',
                Cell: row => {
                  return (
                    <div>
                      <img
                        height={100}
                        alt={row.original.cover}
                        src={row.original.cover}
                      />
                    </div>
                  );
                },
              },
              {
                Header: 'Status',
                accessor: 'status',
              },
              {
                Header: 'Action',
                accessor: 'Action',

                Cell: row => (
                  <div style={{justifyContent: 'center', display: 'flex'}}>
                    <div
                      className="action-icon preview"
                      title="Preview"
                      onClick={event =>
                        window.open(
                          `https://livingwatersmuseum.org/${row.original.subType}`,
                          '_blank'
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </div>
                    <div
                      className="action-icon publish"
                      title="Publish"
                      onClick={() => {
                        this.publishStories(row.original.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faUpload} />
                    </div>
                  </div>
                ),
              },
            ]}
          />
          <div
              className="action-icon add"
              title="Add Resource Data"
              onClick={() => {
                this.updateResources();
                this.setState({
                  operation: 'Add',
                  sName: localStorage.getItem('name'),
                  resourceModalShow: true,
                });
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </div>
            <AddResourceData
            show={this.state.resourceModalShow}
            onHide={this.resourceModalClose}
          />
        </div>
      );
    }
  }
}
export default ResourceAllList;
