import React, {Component} from 'react';
import {Modal, Button, Row, Col, Form} from 'react-bootstrap';
import axios from 'axios';
import '../../index.css';

class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.data,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }

  handleSubmit(event) {
    let image = this.state.image;
    fetch(
      `${process.env.REACT_APP_API_URL}/categories/${this.props.cName}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          label: event.target.label.value,
          desc: event.target.desc.value,
          name: event.target.name.value,
          type: event.target.type.value,
          priority: event.target.priority.value,
          cover: image,
        }),
      }
    ).then(res => res.json());
    event.preventDefault();
    window.confirm(
      '"You are Editing a category to LWM Platform, Please confirm!"'
    );
    window.location.reload();
  }

  fileSelectedHandler = event => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  fileUploadHandler = () => {
    const fd = new FormData();
    fd.append('file', this.state.selectedFile);
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/upload`, fd, {
        onUploadProgress: ProgressEvent => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
          });
        },
      })
      .then(res => {
        this.setState({image: res.data.path});
      });
  };

  render() {
    let {imagePreviewUrl} = this.state;
    // eslint-disable-next-line no-unused-vars
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} alt="" />;
    } else {
      $imagePreview = (
        <div className="previewText">Please select an Image for Preview</div>
      );
    }
    return (
      <div className="container">
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit Categories
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          required
                          disabled
                          placeholder="name"
                          defaultValue={this.props.cName}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="type">
                        <Form.Label>Type</Form.Label>
                        <Form.Control
                          as="select"
                          name="type"
                          defaultValue={this.props.cType}
                        >
                          <option>lwm</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId="label">
                        <Form.Label>Label</Form.Label>
                        <Form.Control
                          type="text"
                          name="label"
                          required
                          placeholder="label"
                          defaultValue={this.props.cLabel}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Priority</Form.Label>
                        <Form.Control
                          type="text"
                          name="priority"
                          defaultValue={this.props.cPriority}
                          disabled
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <Form.Group controlId="desc">
                        <Form.Label>Desc</Form.Label>
                        <Form.Control
                          type="text"
                          name="desc"
                          required
                          placeholder="desc"
                          as="textarea"
                          rows= "10"
                          defaultValue={this.props.cDesc}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <Form.Group>
                        <Form.Label>Icon Upload</Form.Label>
                        <Form.Control
                          type="file"
                          name={this.state.image}
                          onChange={this.fileSelectedHandler}
                        />
                        <Button onClick={this.fileUploadHandler}>
                          Upload Icon Image
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={8}></Col>
                    <Col sm={4} className="text-align-r">
                      <Form.Group>
                        <Button variant="primary" type="submit">
                          Edit Categories
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.onHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default EditModal;
