/* eslint-disable no-redeclare */
// eslint-disable-next-line no-redeclare

import React, { Component } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import { Progress } from "reactstrap";
import "../../index.css";

class EditResourceData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subType: "",
      id: props.data,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(props) {
    console.log("printing props");
    console.log(props);
    if (props.data) {
      this.setState(
        {
          subType: "",
          id: props.data,
          selectedStory: props.selectedStory,
          image: props.rCover,
        },
        () => {}
      );
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let image = this.state.image;
    if (this.props.rSubType === "books") {
      var r = window.confirm(
        "You are editing a resource data to LWM Platform, Please confirm!"
      );
      if (r === true) {
        fetch(
          `${process.env.REACT_APP_API_URL}/stories/info/${this.props.rId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: event.target.id.value,
              name: event.target.name.value,
              type: event.target.type.value,
              subType: event.target.subType.value,
              label: event.target.label.value,
              desc: event.target.desc.value,
              author: event.target.author.value,
              publisher: event.target.publisher.value,
              year: event.target.byear.value,
              isbn: event.target.bisbn.value,
              link: event.target.link.value,
              cover: image,
            }),
          }
        ).then((res) =>
          res.json().then((responsejson) => {
            setTimeout(function () {
              window.location.reload();
            }, 300);
          })
        );
      } else {
      }
    } else if (this.props.rSubType === "films") {
      var r = window.confirm(
        "You are editing a resource data to LWM Platform, Please confirm!"
      );

      if (r === true) {
        fetch(
          `${process.env.REACT_APP_API_URL}/stories/info/${this.props.rId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: event.target.id.value,
              name: event.target.name.value,
              type: event.target.type.value,
              subType: event.target.subType.value,
              label: event.target.label.value,
              desc: event.target.desc.value,
              director: event.target.fdirector.value,
              producer: event.target.fproducer.value,
              year: event.target.fyear.value,
              link: event.target.link.value,
              cover: image,
            }),
          }
        ).then((res) =>
          res.json().then((responsejson) => {
            setTimeout(function () {
              window.location.reload();
            }, 300);
          })
        );
      } else {
      }
    } else if (this.props.rSubType === "audiovideo") {
      var r = window.confirm(
        "You are editing a resource data to LWM Platform, Please confirm!"
      );

      if (r === true) {
        fetch(
          `${process.env.REACT_APP_API_URL}/stories/info/${this.props.rId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: event.target.id.value,
              name: event.target.name.value,
              type: event.target.type.value,
              subType: event.target.subType.value,
              label: event.target.label.value,
              desc: event.target.desc.value,
              supporter: event.target.avSupporter.value,
              publisher: event.target.avPublisher.value,
              creator: event.target.avCreator.value,
              customType: event.target.avType.value,
              link: event.target.link.value,
              cover: image,
            }),
          }
        ).then((res) =>
          res.json().then((responsejson) => {
            setTimeout(function () {
              window.location.reload();
            }, 300);
          })
        );
      } else {
      }
    } else if (this.props.rSubType === "artworks") {
      var r = window.confirm(
        "You are editing a resource data to LWM Platform, Please confirm!"
      );

      if (r === true) {
        fetch(
          `${process.env.REACT_APP_API_URL}/stories/info/${this.props.rId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: event.target.id.value,
              name: event.target.name.value,
              type: event.target.type.value,
              subType: event.target.subType.value,
              label: event.target.label.value,
              desc: event.target.desc.value,
              artist: event.target.awArtist.value,
              facilitator: event.target.awFacilitator.value,
              curator: event.target.awCurator.value,
              gallery: event.target.awGallery.value,
              exhibition: event.target.awExhibition.value,
              link: event.target.link.value,
              cover: image,
            }),
          }
        ).then((res) =>
          res.json().then((responsejson) => {
            setTimeout(function () {
              window.location.reload();
            }, 300);
          })
        );
      } else {
      }
    } else if (this.props.rSubType === "documents") {
      var r = window.confirm(
        "You are editing a resource data to LWM Platform, Please confirm!"
      );

      if (r === true) {
        fetch(
          `${process.env.REACT_APP_API_URL}/stories/info/${this.props.rId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: event.target.id.value,
              name: event.target.name.value,
              type: event.target.type.value,
              subType: event.target.subType.value,
              label: event.target.label.value,
              desc: event.target.desc.value,
              customType: event.target.dType.value,
              author: event.target.dAuthor.value,
              publisher: event.target.dPublisher.value,
              year: event.target.dType.value,
              link: event.target.link.value,
              cover: image,
            }),
          }
        ).then((res) =>
          res.json().then((responsejson) => {
            setTimeout(function () {
              window.location.reload();
            }, 300);
          })
        );
      } else {
      }
    } else if (this.props.rSubType === "people") {
      var r = window.confirm(
        "You are editing a resource data to LWM Platform, Please confirm!"
      );

      if (r === true) {
        fetch(
          `${process.env.REACT_APP_API_URL}/stories/info/${this.props.rId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: event.target.id.value,
              name: event.target.name.value,
              type: event.target.type.value,
              subType: event.target.subType.value,
              label: event.target.label.value,
              desc: event.target.desc.value,
              association: event.target.pplAssociation.value,
              expertise: event.target.pplExpertise.value,
              location: event.target.pplLocation.value,
              link: event.target.link.value,
              cover: image,
            }),
          }
        ).then((res) =>
          res.json().then((responsejson) => {
            setTimeout(function () {
              window.location.reload();
            }, 300);
          })
        );
      } else {
      }
    } else if (this.props.rSubType === "thesis") {
      var r = window.confirm(
        "You are editing a resource data to LWM Platform, Please confirm!"
      );

      if (r === true) {
        fetch(
          `${process.env.REACT_APP_API_URL}/stories/info/${this.props.rId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: event.target.id.value,
              name: event.target.name.value,
              type: event.target.type.value,
              subType: event.target.subType.value,
              label: event.target.label.value,
              desc: event.target.desc.value,
              discipline: event.target.tDiscipline.value,
              ugpg: event.target.tUgPg.value,
              institute: event.target.tInstitute.value,
              year: event.target.tYear.value,
              link: event.target.link.value,
              cover: image,
            }),
          }
        ).then((res) =>
          res.json().then((responsejson) => {
            setTimeout(function () {
              window.location.reload();
            }, 300);
          })
        );
      } else {
      }
    } else if (this.props.rSubType === "organisation") {
      var r = window.confirm(
        "You are editing a resource data to LWM Platform, Please confirm!"
      );

      if (r === true) {
        fetch(
          `${process.env.REACT_APP_API_URL}/stories/info/${this.props.rId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: event.target.id.value,
              name: event.target.name.value,
              type: event.target.type.value,
              subType: event.target.subType.value,
              label: event.target.label.value,
              desc: event.target.desc.value,
              expertise: event.target.oExpertise.value,
              founder: event.target.oFounder.value,
              location: event.target.oLocation.value,
              year: event.target.oYear.value,
              link: event.target.link.value,
              cover: image,
            }),
          }
        ).then((res) =>
          res.json().then((responsejson) => {
            setTimeout(function () {
              window.location.reload();
            }, 300);
          })
        );
      } else {
      }
    } else if (this.props.rSubType === "articles") {
      var r = window.confirm(
        "You are editing a resource data to LWM Platform, Please confirm!"
      );

      if (r === true) {
        fetch(
          `${process.env.REACT_APP_API_URL}/stories/info/${this.props.rId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: event.target.id.value,
              name: event.target.name.value,
              type: event.target.type.value,
              subType: event.target.subType.value,
              label: event.target.label.value,
              desc: event.target.desc.value,
              customType: event.target.aType.value,
              author: event.target.aAuthor.value,
              publisher: event.target.aPublisher.value,
              year: event.target.aYear.value,
              link: event.target.link.value,
              cover: image,
            }),
          }
        ).then((res) =>
          res.json().then((responsejson) => {
            setTimeout(function () {
              window.location.reload();
            }, 300);
          })
        );
      } else {
      }
    } else if (this.props.rSubType === "projects") {
      var r = window.confirm(
        "You are editing a resource data to LWM Platform, Please confirm!"
      );

      if (r === true) {
        fetch(
          `${process.env.REACT_APP_API_URL}/stories/info/${this.props.rId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: event.target.id.value,
              name: event.target.name.value,
              type: event.target.type.value,
              subType: event.target.subType.value,
              label: event.target.label.value,
              desc: event.target.desc.value,
              domain: event.target.pDomain.value,
              collaborator: event.target.pCollaborator.value,
              Facilitator: event.target.pfacilitator.value,
              owner: event.target.pOwner.value,
              participants: event.target.pParticipants.value,
              link: event.target.link.value,
              cover: image,
            }),
          }
        ).then((res) =>
          res.json().then((responsejson) => {
            setTimeout(function () {
              window.location.reload();
            }, 300);
          })
        );
      } else {
      }
    }
  };

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }

  fileSelectedHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  fileUploadHandler = () => {
    const fd = new FormData();
    fd.append("file", this.state.selectedFile);
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/upload`, fd, {
        onUploadProgress: (ProgressEvent) => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
          });
        },
      })
      .then((res) => {
        this.setState({ image: res.data.path });
      });
  };

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Resource Data Story
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <Row>
              <Col sm={12}>
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId="id">
                        <Form.Label>Id</Form.Label>
                        <Form.Control
                          type="number"
                          name="id"
                          placeholder="id"
                          disabled
                          defaultValue={this.props.rId}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="name"
                          defaultValue={this.props.rName}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId="type">
                        <Form.Label>Type</Form.Label>
                        <Form.Control as="select" name="type">
                          <option>resources</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="subType">
                        <Form.Label>SubType</Form.Label>
                        <Form.Control
                          name="subType"
                          placeholder="subType"
                          defaultValue={this.props.rSubType}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId="label">
                        <Form.Label>Label</Form.Label>
                        <Form.Control
                          type="text"
                          name="label"
                          placeholder="label"
                          defaultValue={this.props.rLabel}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="link">
                        <Form.Label>link </Form.Label>
                        <Form.Control
                          type="text"
                          name="link"
                          placeholder="link"
                          defaultValue={this.props.rLink}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <Form.Group controlId="desc">
                        <Form.Label>About</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="6"
                          type="text"
                          name="desc"
                          placeholder="description"
                          defaultValue={this.props.rDesc}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {this.props && this.props.rSubType === "books" ? (
                    <div>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="author">
                            <Form.Label>Author</Form.Label>
                            <Form.Control
                              type="text"
                              name="author"
                              placeholder="author"
                              defaultValue={this.props.rAuthor}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="publisher">
                            <Form.Label>Publisher</Form.Label>
                            <Form.Control
                              type="text"
                              name="publisher"
                              placeholder="publisher"
                              defaultValue={this.props.rPublisher}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="bisbn">
                            <Form.Label>ISBN</Form.Label>
                            <Form.Control
                              type="text"
                              name="bisbn"
                              placeholder="ISBN"
                              defaultValue={this.props.selectedStory.isbn}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="byear">
                            <Form.Label>Publisher</Form.Label>
                            <Form.Control
                              type="text"
                              name="byear"
                              placeholder="Year"
                              defaultValue={this.props.selectedStory.year}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  ) : this.props && this.props.rSubType === "films" ? (
                    <div>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="director">
                            <Form.Label>Director</Form.Label>
                            <Form.Control
                              type="text"
                              name="fdirector"
                              placeholder="director"
                              defaultValue={this.props.selectedStory.director}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="fproducer">
                            <Form.Label>Producer</Form.Label>
                            <Form.Control
                              type="text"
                              name="fproducer"
                              placeholder="producer"
                              defaultValue={this.props.selectedStory.producer}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="fyear">
                            <Form.Label>year</Form.Label>
                            <Form.Control
                              type="text"
                              name="fyear"
                              placeholder="year"
                              defaultValue={this.props.selectedStory.year}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  ) : this.props && this.props.rSubType === "audiovideo" ? (
                    <div>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="creator">
                            <Form.Label>Creator</Form.Label>
                            <Form.Control
                              type="text"
                              name="avCreator"
                              placeholder="creator"
                              defaultValue={this.props.selectedStory.creator}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="avtype">
                            <Form.Label>Type</Form.Label>
                            <Form.Control
                              type="text"
                              name="avType"
                              placeholder="Type"
                              defaultValue={this.props.selectedStory.customType}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="creator">
                            <Form.Label>Supporter</Form.Label>
                            <Form.Control
                              type="text"
                              name="avSupporter"
                              placeholder="supporter"
                              defaultValue={this.props.selectedStory.supporter}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="avtype">
                            <Form.Label>Publisher</Form.Label>
                            <Form.Control
                              type="text"
                              name="avPublisher"
                              placeholder="Publisher"
                              defaultValue={this.props.selectedStory.publisher}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  ) : this.props && this.props.rSubType === "artworks" ? (
                    <div>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="awArtist">
                            <Form.Label>Artist</Form.Label>
                            <Form.Control
                              type="text"
                              name="awArtist"
                              placeholder="artist"
                              defaultValue={this.props.selectedStory.artist}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="avtype">
                            <Form.Label>Facilitator</Form.Label>
                            <Form.Control
                              type="text"
                              name="awFacilitator"
                              placeholder="facilitator"
                              defaultValue={
                                this.props.selectedStory.facilitator
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="creator">
                            <Form.Label>Curator</Form.Label>
                            <Form.Control
                              type="text"
                              name="awCurator"
                              placeholder="curator"
                              defaultValue={this.props.selectedStory.curator}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="avtype">
                            <Form.Label>Gallery</Form.Label>
                            <Form.Control
                              type="text"
                              name="awGallery"
                              placeholder="gallery"
                              defaultValue={this.props.selectedStory.gallery}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="creator">
                            <Form.Label>Exhibition</Form.Label>
                            <Form.Control
                              type="text"
                              name="awExhibition"
                              placeholder="exhibition"
                              defaultValue={this.props.selectedStory.exhibition}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  ) : this.props && this.props.rSubType === "documents" ? (
                    <div>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="awArtist">
                            <Form.Label>Type</Form.Label>
                            <Form.Control
                              type="text"
                              name="dType"
                              placeholder="type"
                              defaultValue={this.props.selectedStory.customType}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="avtype">
                            <Form.Label>Author</Form.Label>
                            <Form.Control
                              type="text"
                              name="dAuthor"
                              placeholder="author"
                              defaultValue={this.props.selectedStory.author}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="creator">
                            <Form.Label>Year</Form.Label>
                            <Form.Control
                              type="text"
                              name="dYear"
                              placeholder="year"
                              defaultValue={this.props.selectedStory.year}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="avtype">
                            <Form.Label>Publisher</Form.Label>
                            <Form.Control
                              type="text"
                              name="dPublisher"
                              placeholder="publisher"
                              defaultValue={this.props.selectedStory.publisher}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  ) : this.props && this.props.rSubType === "people" ? (
                    <div>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="awArtist">
                            <Form.Label>Association</Form.Label>
                            <Form.Control
                              type="text"
                              name="pplAssociation"
                              placeholder="association"
                              defaultValue={
                                this.props.selectedStory.association
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="avtype">
                            <Form.Label>Expertise</Form.Label>
                            <Form.Control
                              type="text"
                              name="pplExpertise"
                              placeholder="expertise"
                              defaultValue={this.props.selectedStory.expertise}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="creator">
                            <Form.Label>Location</Form.Label>
                            <Form.Control
                              type="text"
                              name="pplLocation"
                              placeholder="location"
                              defaultValue={this.props.selectedStory.location}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  ) : this.props && this.props.rSubType === "thesis" ? (
                    <div>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="awArtist">
                            <Form.Label>Discipline</Form.Label>
                            <Form.Control
                              type="text"
                              name="tDiscipline"
                              placeholder="Discipline"
                              defaultValue={this.props.selectedStory.discipline}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="avtype">
                            <Form.Label>UG/PG</Form.Label>
                            <Form.Control
                              type="text"
                              name="tUgPg"
                              placeholder="UG/PG"
                              defaultValue={this.props.selectedStory.ugpg}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="creator">
                            <Form.Label>Institute</Form.Label>
                            <Form.Control
                              type="text"
                              name="tInstitute"
                              placeholder="Institute"
                              defaultValue={this.props.selectedStory.institute}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="creator">
                            <Form.Label>Year of Publishing</Form.Label>
                            <Form.Control
                              type="text"
                              name="tYear"
                              placeholder="Year of Publishing"
                              defaultValue={this.props.selectedStory.year}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  ) : this.props && this.props.rSubType === "organisation" ? (
                    <div>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="awArtist">
                            <Form.Label>Expertises</Form.Label>
                            <Form.Control
                              type="text"
                              name="oExpertise"
                              placeholder="Expertises"
                              defaultValue={this.props.selectedStory.expertise}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="avtype">
                            <Form.Label>Founder</Form.Label>
                            <Form.Control
                              type="text"
                              name="oFounder"
                              placeholder="Founder"
                              defaultValue={this.props.selectedStory.founder}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="creator">
                            <Form.Label>Location</Form.Label>
                            <Form.Control
                              type="text"
                              name="oLocation"
                              placeholder="Location"
                              defaultValue={this.props.selectedStory.location}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="creator">
                            <Form.Label>Started In</Form.Label>
                            <Form.Control
                              type="text"
                              name="oYear"
                              placeholder="Started In"
                              defaultValue={this.props.selectedStory.year}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  ) : this.props && this.props.rSubType === "articles" ? (
                    <div>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="awArtist">
                            <Form.Label>Type</Form.Label>
                            <Form.Control
                              type="text"
                              name="aType"
                              placeholder="Type"
                              defaultValue={this.props.selectedStory.customType}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="avtype">
                            <Form.Label>Author</Form.Label>
                            <Form.Control
                              type="text"
                              name="aAuthor"
                              placeholder="Author"
                              defaultValue={this.props.selectedStory.author}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="creator">
                            <Form.Label>Publisher</Form.Label>
                            <Form.Control
                              type="text"
                              name="aPublisher"
                              placeholder="Publisher"
                              defaultValue={this.props.selectedStory.publisher}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="creator">
                            <Form.Label>Year of Publishing</Form.Label>
                            <Form.Control
                              type="text"
                              name="aYear"
                              placeholder="Year of Publishing"
                              defaultValue={this.props.selectedStory.year}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  ) : this.props && this.props.rSubType === "projects" ? (
                    <div>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="awArtist">
                            <Form.Label>Domain</Form.Label>
                            <Form.Control
                              type="text"
                              name="pDomain"
                              placeholder="Domain"
                              defaultValue={this.props.selectedStory.domain}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="avtype">
                            <Form.Label>Collaborator</Form.Label>
                            <Form.Control
                              type="text"
                              name="pCollaborator"
                              placeholder="Collaborator"
                              defaultValue={
                                this.props.selectedStory.collaborator
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="creator">
                            <Form.Label>Facilitator</Form.Label>
                            <Form.Control
                              type="text"
                              name="pFacilitator"
                              placeholder="Facilitator"
                              defaultValue={
                                this.props.selectedStory.facilitator
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="creator">
                            <Form.Label>Owner</Form.Label>
                            <Form.Control
                              type="text"
                              name="pOwner"
                              placeholder="Owner"
                              defaultValue={this.props.selectedStory.owner}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="creator">
                            <Form.Label>Participants</Form.Label>
                            <Form.Control
                              type="text"
                              name="pParticipants"
                              placeholder="Participants"
                              defaultValue={
                                this.props.selectedStory.participants
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  ) : null}

                  <Row>
                    <Col sm={12}>
                      <Form.Group>
                        <Form.Label>Cover Upload</Form.Label>
                        <Form.Control
                          //defaultValue={this.props.rCover}
                          type="file"
                          name={this.state.image}
                          onChange={this.fileSelectedHandler}
                        />
                        {/* <div>
                          <input
                            className="fileInput"
                            type="file"
                            onChange={e => this._handleImageChange(e)}
                          />
                        </div> */}
                        <Button onClick={this.fileUploadHandler}>
                          Upload Cover Image
                        </Button>
                        <Progress
                          max="100"
                          color="success"
                          value={this.state.loaded}
                        >
                          {Math.round(this.state.loaded, 2)}%
                        </Progress>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={8}></Col>
                    <Col sm={4} className="text-align-r">
                      <Form.Group>
                        <Button type="submit">Add Resource Data</Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditResourceData;
