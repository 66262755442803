import React from "react";

class ArtWorks extends React.Component {
  constructor(props) {
    super(props);
    this.state = { textContent: "", textCaption: "" };
  }

  onChange = (evt) => {
    this.setState({
      textContent: evt.editor.getData(),
    });
  };

  onPChange = (evt) => {
    this.setState({
      textCaption: evt.editor.getData(),
    });
  };

  render() {
    console.log(this.state);
    return (
      <div className="col-sm-12 no-padding">
        <div className="row">
          <div className="mar-b-16 col-sm-6 no-l-padding">
            <h6>Artist</h6>
            <input
              type="text"
              name="awArtist"
              placeholder="Enter ArtWorks Artist"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
          <div className="mar-b-16 col-sm-6">
            <h6>Facilitator</h6>
            <input
              type="text"
              name="awfacilitator"
              placeholder="Enter ArtWorks Facilitator"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
        </div>
        <div className="row">
          <div className="mar-b-16 col-sm-6">
            <h6>Curator</h6>
            <input
              type="text"
              name="awCurator"
              placeholder="Enter Artworks Curator"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
          <div className="mar-b-16 col-sm-6">
            <h6>Gallery</h6>
            <input
              type="text"
              name="awGallery"
              placeholder="Enter ArtWors Gallery"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
        </div>
        <div className="row">
          <div className="mar-b-16 col-sm-12">
            <h6>Exhibition Platform</h6>
            <input
              type="text"
              name="awExhibition"
              placeholder="Enter Artworks Exhibition Platform"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
        </div>
        <div className="row">
          <div className="mar-b-16 col-sm-12">
            <h6>Abstract</h6>
            <input
              type="textarea"
              name="awAbstract"
              placeholder="Enter ArtWorks Abstract"
              style={{ width: "100%", height: 48 }}
              maxlength="1300"
            />
          </div>
        </div>
      </div>
    );
  }
}
export default ArtWorks;
