/* eslint-disable jsx-a11y/iframe-has-title */

import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";
import EditStoryData from "./EditStoryData";
import AddStoryDataModal from "./AddStoryDataModal";

class StoryDataList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editModalShow: false,
      stories: [],
      id: "",
      order: "",
      isLoaded: false,
      addDataModalShow: false,
      selectedCategory: {},
    };
    this.updateStories = this.updateStories.bind(this);
    this.myFunction = this.myFunction.bind(this);
  }

  componentDidMount() {
    const id = localStorage.getItem("id");
    fetch(`${process.env.REACT_APP_API_URL}/stories/info/` + id)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            stories: result.stories,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  updateStories = (sid, cdata) => {
    cdata.sid = sid;
    this.setState({ selectedCategory: cdata }, this.myFunction);
  };

  myFunction = () => {
    this.setState({
      editModalShow: true,
    });
  };

  updateStoryData(cData) {
    this.setState({
      selectedCategory: cData,
    });
  }

  deleteStories(blockid, id) {
    if (window.confirm("Are you sure?")) {
      const token = localStorage.jwtToken ? localStorage.jwtToken : '';
      fetch(
        `${process.env.REACT_APP_API_URL}/stories/${id}/block/${blockid}`,
        {
          method: "DELETE",
          headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            'x-access-token': token,
          },
        }
      )
        .then((res) => res.json())
        .then((responseJson) => {
          window.location.reload();
        });
    }
  }

  editModalClose = () => {
    this.setState({ editModalShow: !this.state.editModalShow });
  };

  addDataModalClose = () => {
    this.setState({ addDataModalShow: !this.state.addDataModalShow });
  };

  render() {
    const {
      error,
      isLoaded,
      stories,
      // sUrl,
      // sCaption,
      // sContent,
      // sOrderId,
      sId,
      // sType,
    } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="categories-container loader"></div>;
    } else {
      return (
        <div className="categories-container">
          <div>
            {stories.map((stories, index) => (
              <div key={index}>
                { stories.data &&
                  stories.data.map((data, index) => (
                  <Table
                    striped
                    bordered
                    hover
                    key={index}
                    className="story-data"
                  >
                    <thead>
                      <tr>
                        <th>Order</th>
                        <th>Type</th>
                        <th>Title</th>
                        <th>Image</th>
                        <th>Caption</th>
                        <th>Text</th>
                        <th>video</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{data.order}</td>
                        <td>{data.type}</td>
                        <td>{data.title}</td>
                        <td>
                          <img src={data.url} alt={data.imagename} />
                        </td>
                        <td>{data.caption}</td>
                        <td>{data.content}</td>
                        <td>
                          <iframe
                            style={{
                              top: 0,
                              left: 0,
                              width: "150px",
                              height: "150px",
                            }}
                            src={data.videourl}
                            frameBorder="0"
                          />
                        </td>
                        <td>
                          {" "}
                          <div
                            className="action-icon delete"
                            title="Delete"
                            onClick={() => {
                              this.deleteStories(data.order, stories.id);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </div>
                          <div
                            className="action-icon edit"
                            title="Edit"
                            onClick={() => {
                              this.updateStories(stories.id, data);
                            }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ))}
              </div>
            ))}
          </div>
          <div
            className="action-icon add"
            title="Add Story Block"
            onClick={() => {
              //this.updateStoryData();
              this.setState({
                operation: "Add",
                sId: localStorage.getItem("id"),
                addDataModalShow: true,
              });
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            <p>Add Data</p>
          </div>
          <EditStoryData
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            sid={this.state.selectedCategory.sid}
            stype={this.state.selectedCategory.type}
            sorderid={this.state.selectedCategory.order}
            stitle={this.state.selectedCategory.title}
            scontent={this.state.selectedCategory.content}
            scaption={this.state.selectedCategory.caption}
            surl={this.state.selectedCategory.url}
            threed={this.state.selectedCategory.threed}
            vr={this.state.selectedCategory.vr}
            audio={this.state.selectedCategory.audio}
          />
          <AddStoryDataModal
            show={this.state.addDataModalShow}
            onHide={this.addDataModalClose}
            sId={sId}
          />
        </div>
      );
    }
  }
}

export default StoryDataList;
