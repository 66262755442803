/* eslint-disable jsx-a11y/alt-text */
import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrash, faPlus} from '@fortawesome/free-solid-svg-icons';
import {Table} from 'react-bootstrap';
import EditResourceData from './EditResourceData';
import AddResourceData from './AddResourceData';

class ResourceDataList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addModalShow: false,
      editModalShow: false,
      name: '',
      storyList: [],
      isLoaded: false,
      selectedStory : {}
    };
  }

  componentDidMount() {
    let name = JSON.parse(localStorage.getItem('name'));
    fetch(
      `${process.env.REACT_APP_API_URL}/stories/all/resources/` + name
    )
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            storyList: result.storyList,
          });
        },
        error => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  updateStories(cData) {
    this.setState({
      selectedCategory: cData,
    });
  }

  updateResources(cData) {
    this.setState({
      selectedCategory: cData,
    });
  }

  updateStoryData(cData) {
    this.setState({
      selectedCategory: cData,
    });
  }

  deleteStories(id) {
    if (window.confirm('Are you sure?')) {
      fetch(`${process.env.REACT_APP_API_URL}/stories/` + id, {
        method: 'DELETE',
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    }
  }

  editModalClose = () => {
    this.setState({editModalShow: !this.state.editModalShow});
  };

  resourceModalClose = () => this.setState({resourceModalShow: false});

  render() {
    const {
      storyList,
      rLink,
      rName,
      rPublisher,
      rSubType,
      rType,
      rBrief,
      rDesc,
      rIcon,
      rAuthor,
      rId,
      rLabel,
      rCover,
      error,
      isLoaded,
      selectedStory
    } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="categories-container loader"></div>;
    } else {
      return (
        <div className="categories-container">
          <div>
            {storyList.map(storyList => (
              <div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th style={{width:"15%"}}>Name</th>
                      <th style={{width:"35%"}}>Desciption</th>
                      <th style={{width:"15%"}}>Link</th>
                      <th style={{width:"15%"}}>Cover</th>
                      <th style={{width:"5%"}}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{width:"15%"}}>{storyList.label}</td>
                      <td style={{wordBreak: "break-word",width:"35%"}}>{storyList.desc}</td>
                      <td style={{width:"15%"}}>{storyList.link}</td>
                      <td style={{width:"15%"}}>
                        <img
                          src={storyList.cover}
                          style={{width: 150, height: 150}}
                        />
                      </td>
                      <td style={{width:"5%"}}>
                        {' '}
                        <div
                          className="action-icon delete"
                          title="Delete"
                          onClick={() => {
                            this.deleteStories(storyList.id);
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </div>
                        <div
                          className="action-icon edit"
                          title="Edit"
                          onClick={() => {
                            this.updateStories();
                            this.setState({
                              operation: 'Update',
                              editModalShow: true,
                              rId: storyList.id,
                              rName: storyList.name,
                              rBrief: storyList.brief,
                              rLabel: storyList.label,
                              rSubType: storyList.subType,
                              rLink: storyList.link,
                              rPublisher: storyList.publisher,
                              rAuthor: storyList.author,
                              rDesc: storyList.desc,
                              rIcon: storyList.icon,
                              rCover: storyList.cover,
                              selectedStory: storyList
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            ))}
            <div
              className="action-icon add"
              title="Add Resource Data"
              onClick={() => {
                this.updateResources();
                this.setState({
                  operation: 'Add',
                  sName: localStorage.getItem('name'),
                  resourceModalShow: true,
                });
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </div>
          <EditResourceData
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            rId={rId}
            rName={rName}
            rType={rType}
            rSubType={rSubType}
            rLabel={rLabel}
            rDesc={rDesc}
            rBrief={rBrief}
            rAuthor={rAuthor}
            rPublisher={rPublisher}
            rLink={rLink}
            rIcon={rIcon}
            rCover={rCover}
            selectedStory={selectedStory}
          />
          <AddResourceData
            show={this.state.resourceModalShow}
            onHide={this.resourceModalClose}
          />
        </div>
      );
    }
  }
}

export default ResourceDataList;
