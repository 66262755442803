import React, { Component } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import { Progress } from "reactstrap";
import "../../index.css";
import { TagInput } from "reactjs-tag-input";

class EditStoriesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.data,
      file: "",
      imagePreviewUrl: "",
      selectedFile: null,
      tags: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onTagsChanged = this.onTagsChanged.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    let image = this.state.image;
    var r = window.confirm(
      "You are editing a story data to LWM Platform, Please confirm!"
    );
    if (r === true) {
      const token = localStorage.jwtToken ? localStorage.jwtToken : '';
      fetch(
        `${process.env.REACT_APP_API_URL}/stories/info/${this.props.sId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            'x-access-token': token,
          },
          body: JSON.stringify({
            id: event.target.id.value,
            name: event.target.name.value,
            label: event.target.label.value,
            de: event.target.de.value,
            author: event.target.author.value,
            icon: image,
            cover: image,
            tags: this.state.tags,
            location: {
              locationName: event.target.locationName.value,
              lat: event.target.lat.value,
              lon: event.target.lon.value,
            },
          }),
        }
      ).then((res) =>
        res.json().then((responsejson) => {
          setTimeout(function () {
            window.location.reload();
          }, 500);
        })
      );
    } else {
    }
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }

  onTagsChanged(tags) {
    this.setState({ tags });
  }

  fileSelectedHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
    let reader = new FileReader();

    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  fileUploadHandler = (e) => {
    const fd = new FormData();
    fd.append("file", this.state.selectedFile);
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/upload`, fd, {
        onUploadProgress: (ProgressEvent) => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
            file: this.state.selectedFile,
          });
        },
      })
      .then((res) => {
        this.setState({ image: res.data.path });
      });
  };

  componentWillReceiveProps(props) {
    // const {
    //   sId,
    //   sName,
    //   sDe,
    //   sType,
    //   sSubtype,
    //   sLongitude,
    //   sLatitude,
    //   sAuthor,
    //   sBrief,
    //   sLabel,
    //   sIcon,
    //   sLocationName,
    //   sTag,
    // } = this.props;
    if (props.show !== false) {
      this.setState(
        {
          id: props.sId,
          label: props.sLabel,
          desc: props.sDe,
          type: props.stype,
          author: props.sAuthor,
          location: props.sLocationName,
          lon: props.sLongitude,
          lat: props.sLatitude,
          image: props.sIcon,
          tags: props.sTag,
          imagePreviewUrl: props.sIcon,
        },
        () => {}
      );
    }
  }

  render() {
    let $imagePreview = (
      <div className="previewText image-container">
        Please select an Image for Preview
      </div>
    );
    if (this.state.imagePreviewUrl) {
      $imagePreview = (
        <div className="image-container">
          <img src={this.state.imagePreviewUrl} alt="icon" width="200" />{" "}
        </div>
      );
    }

    return (
      <div className="container">
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit Stories
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form
                  onSubmit={this.handleSubmit}
                  onKeyPress={(event) => {
                    if (event.which === 13 /* Enter */) {
                      event.preventDefault();
                    }
                  }}
                >
                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId="subType">
                        <Form.Label>Subtype</Form.Label>
                        <Form.Control
                          type="text"
                          name="subType"
                          required
                          placeholder="subType"
                          defaultValue={this.props.sSubtype}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId="label">
                        <Form.Label>label</Form.Label>
                        <Form.Control
                          type="text"
                          name="label"
                          required
                          placeholder="label"
                          defaultValue={this.props.sLabel}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="author">
                        <Form.Label>Author</Form.Label>
                        <Form.Control
                          type="text"
                          name="author"
                          required
                          placeholder="Author"
                          defaultValue={this.props.sAuthor}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <Form.Group controlId="desc">
                        <Form.Label>Descrption</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="7"
                          type="text"
                          name="de"
                          required
                          placeholder="desc"
                          defaultValue={this.props.sDe}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId="Location Name">
                        <Form.Label>Location Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="locationName"
                          required
                          placeholder="Location Name"
                          defaultValue={this.props.sLocationName}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId="latitude">
                        <Form.Label>Latitude</Form.Label>
                        <Form.Control
                          type="text"
                          name="lat"
                          required
                          placeholder="latitude"
                          defaultValue={this.props.sLatitude}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="longitude">
                        <Form.Label>Longitude</Form.Label>
                        <Form.Control
                          type="text"
                          name="lon"
                          required
                          placeholder="longitude"
                          defaultValue={this.props.sLongitude}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="tags-row">
                    <p className="tags-title">Tags</p>
                    <TagInput
                      className="custom-tags"
                      tags={this.state.tags}
                      onTagsChanged={this.onTagsChanged}
                    />
                  </Row>
                  <Form.Group>
                    <Row>
                      <Col sm={12}>
                        <input
                          type="file"
                          name={this.state.image}
                          onChange={this.fileSelectedHandler}
                          className="fileInput"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <div
                          className="imgPreview"
                          style={{
                            border: "1px solid",
                            width: "auto",
                            height: "220px",
                            margin: "20px",
                          }}
                        >
                          {$imagePreview}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <div>
                          <Button onClick={this.fileUploadHandler}>
                            Upload Cover Image
                          </Button>
                          <Progress
                            max="100"
                            color="success"
                            value={this.state.loaded}
                          >
                            {Math.round(this.state.loaded, 2)}%
                          </Progress>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Row>
                    <Col sm={8}></Col>
                    <Col sm={4} className="text-align-r">
                      <Form.Group>
                        <Button variant="primary" type="submit">
                          Update
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.onHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default EditStoriesModal;
