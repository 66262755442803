import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import EditModal from './EditModal';
import AddCategories from './AddCategories';
import {Button} from 'react-bootstrap';

import '../../index.css';

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addModalShow: false,
      editModalShow: false,
      categories: [],
      selectedCategory: {},
      isLoaded: false,
    };
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/categories`)
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            categories: result.categories,
          });
        },
        error => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  updateCategory(cData) {
    this.setState({
      selectedCategory: cData,
    });
  }

  deleteCatg(name) {
    if (window.confirm('Are you sure?')) {
      fetch(`${process.env.REACT_APP_API_URL}/categories/` + name, {
        method: 'DELETE',
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    }
  }

  editModalClose = () => {
    this.setState({editModalShow: !this.state.editModalShow});
  };

  addModalClose = () => this.setState({addModalShow: false});

  render() {
    const {
      error,
      isLoaded,
      cName,
      cLabel,
      cDesc,
      cType,
      cPriority,
      cCover,
    } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="categories-container loader"></div>;
    } else {
      return (
        <div className="categories-container">
          <div className="title-container">
            <div className="title-left-container">
              <h3> Categories </h3>
            </div>
            <div className="title-right-container">
              <Button
                className="add-item-btn"
                variant="primary"
                onClick={() => this.setState({addModalShow: true})}
              >
                Add Categories
              </Button>
            </div>

            <AddCategories
              show={this.state.addModalShow}
              onHide={this.addModalClose}
            />
          </div>
          <div>
            <ReactTable
              loading={false}
              defaultPageSize={10}
              data={this.state.categories}
              columns={[
                {
                  Header: 'Label',
                  accessor: 'label',
                  style: {whiteSpace: 'unset'},
                },
                // {
                //   Header: 'Type',
                //   accessor: 'type',
                // },
                {
                  Header: 'Desc',
                  accessor: 'desc',
                  width: 150,
                  style: {whiteSpace: 'unset',height: 100},
                },
                // {
                //   Header: 'Priority',
                //   accessor: 'priority',
                // },
                {
                  Header: 'Icon',
                  Cell: row => {
                    return (
                      <div>
                        <img
                          height={64}
                          alt={row.original.icon}
                          src={row.original.icon}
                        />
                      </div>
                    );
                  },
                },
                {
                  Header: 'cover',
                  Cell: row => {
                    return (
                      <div>
                        <img
                          height={64}
                          alt={row.original.cover}
                          src={row.original.cover}
                        />
                      </div>
                    );
                  },
                },
                {
                  Header: 'Action',
                  accessor: 'Action',

                  Cell: _row => (
                    <div>
                      <div
                        className="action-icon"
                        title="Edit"
                        onClick={() => {
                          this.updateCategory(_row.original);
                          this.setState({
                            operation: 'Update',
                            selectedRow: _row.original,
                            editModalShow: true,
                            selectedCategory: _row.original,
                            cCategory: _row.original,
                            cName: _row.original.name,
                            cLabel: _row.original.label,
                            cDesc: _row.original.desc,
                            cType: _row.original.type,
                            cPriority: _row.original.priority,
                            cCover: _row.original.cover,
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                      </div>

                      <div
                        className="action-icon delete"
                        title="Delete"
                        onClick={() => {
                          this.deleteCatg(_row.original.name);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </div>
                    </div>
                  ),
                },
              ]}
            />
            <EditModal
              show={this.state.editModalShow}
              onHide={this.editModalClose}
              cLabel={cLabel}
              cName={cName}
              cDesc={cDesc}
              cType={cType}
              cPriority={cPriority}
              cCover={cCover}
            />
          </div>
        </div>
      );
    }
  }
}

export default Categories;
