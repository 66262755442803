import React from "react";

class Organisation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { textContent: "", textCaption: "" };
  }

  onChange = (evt) => {
    this.setState({
      textContent: evt.editor.getData(),
    });
  };

  onPChange = (evt) => {
    this.setState({
      textCaption: evt.editor.getData(),
    });
  };

  render() {
    console.log(this.state);
    return (
      <div className="col-sm-12 no-padding">
        <div className="row">
          <div className="mar-b-16 col-sm-6 no-l-padding">
            <h6>Expertises</h6>
            <input
              type="text"
              name="oExpertise"
              placeholder="Enter Expertises"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
          <div className="mar-b-16 col-sm-6">
            <h6>Founder</h6>
            <input
              type="text"
              name="oFounder"
              placeholder="Enter Founder"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
        </div>
        <div className="row">
          <div className="mar-b-16 col-sm-6">
            <h6>Location</h6>
            <input
              type="text"
              name="oLocation"
              placeholder="Enter Location"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
          <div className="mar-b-16 col-sm-6">
            <h6>Started In</h6>
            <input
              type="text"
              name="oYear"
              placeholder="Enter Year"
              style={{ width: "100%", height: 48 }}
              maxlength="4"
            />
          </div>
        </div>
        <div className="row">
          <div className="mar-b-16 col-sm-12">
            <h6>About</h6>
            <input
              type="textarea"
              name="oAbstract"
              placeholder="Enter Organisation About"
              style={{ width: "100%", height: 48 }}
              maxlength="1300"
            />
          </div>
        </div>
      </div>
    );
  }
}
export default Organisation;
