/* eslint-disable no-unused-vars */
import React, {Component} from 'react';
import {Modal, Button, Row, Col, Form} from 'react-bootstrap';
import axios from 'axios';
import {Progress} from 'reactstrap';
import './stories.css';
import '../../index.css';
import { TagInput } from 'reactjs-tag-input';

class AddStoriesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      image: '',
      subType: '',
      selectedOption: '',
      tags: []
    };
    this.radioChange = this.radioChange.bind(this);
    this.onTagsChanged = this.onTagsChanged.bind(this);
  }


  onTagsChanged(tags) {
    this.setState({tags})
}

  radioChange(e) {
    this.setState({
      selectedOption: e.currentTarget.value,
    });
  }

  handleSubmit = event => {
    let image = this.state.image;
    event.preventDefault();
    const token = localStorage.jwtToken ? localStorage.jwtToken : '';
    fetch(`${process.env.REACT_APP_API_URL}/stories/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
      body: JSON.stringify({
        type: "category",
        subType: event.target.subType.value,
        label: event.target.label.value,
        de: event.target.de.value,
        //brief: event.target.brief.value,
        author: event.target.author.value,
        icon: image,
        icon_class: event.target.icon_class.value,
        cover: image,
        tags: this.state.tags,
        location: {
          locationName: event.target.locationName.value,
          lat: event.target.lat.value,
          lon: event.target.lon.value,
        },
      }),
    }).then(res => res.json()).then(responseJson => {
      window.location.reload();
    });
   
   
    // window.confirm(
    //   '"You are uploading a new story to LWM Platform, Please confirm!"'
    // );
    // window.location.reload();
  };

  fileSelectedHandler = event => {
    this.setState({
      selectedFile: event.target.files[0],
    });
    let reader = new FileReader();

    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  fileUploadHandler = e => {
    const fd = new FormData();
    fd.append('file', this.state.selectedFile);
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/upload`, fd, {
        onUploadProgress: ProgressEvent => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
            file: this.state.selectedFile,
          });
        },
      })
      .then(res => {
        this.setState({image: res.data.path});
      });
  };

  render() {
    let $imagePreview = (
      <div className="previewText image-container">
        select an Image for Preview
      </div>
    );
    if (this.state.imagePreviewUrl) {
      $imagePreview = (
        <div className="image-container">
          <img src={this.state.imagePreviewUrl} alt="icon" width="200" />{' '}
        </div>
      );
    }
    let $imageview = (
      <div className="previewText image-container">Image for Preview</div>
    );
    if (this.state.imagePreviewUrl) {
      $imageview = (
        <div className="image-container">
          <img
            src={this.state.imagePreviewUrl}
            alt="icon"
            width="100"
            className={'clippath-style ' + this.state.selectedOption}
          />{' '}
        </div>
      );
    }
    let $imageCoverview = (
      <div className="previewText image-container">Image Preview</div>
    );
    if (this.state.imagePreviewUrl) {
      $imageCoverview = (
        <div className="image-container">
          <img src={this.state.imagePreviewUrl} alt="icon" width="250px" />{' '}
        </div>
      );
    }
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Stories
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <Row>
              <Col sm={12}>
                {/* <Form onSubmit={this.handleSubmit}> */}
                <Form onSubmit={this.handleSubmit} onKeyPress={event => {
                  if (event.which === 13 /* Enter */) {
                    event.preventDefault();
                  }
                }}>
                  <Row>
                    <Col sm={6}>
                      {/* <Form.Group controlId="type">
                        <Form.Label>Type</Form.Label>
                        <Form.Control as="select" name="type">
                          <option>category</option>
                          <option>resource</option>
                        </Form.Control>
                      </Form.Group> */}
                    </Col>
                    {/* <Col sm={6}>
                      <Form.Group controlId="subType">
                        <Form.Label>SubType</Form.Label>
                        <Form.Control as="select" name="subType">
                          <option>rivers-of-india</option>
                          <option>communities-and-livelihoods</option>
                          <option>music-art-performances</option>
                          <option>architecture-design</option>
                          <option>traditions-and-ritual-practices</option>
                          <option>ecology-and-nature</option>
                          <option>exhibitions</option>
                        </Form.Control>
                      </Form.Group>
                    </Col> */}
                  </Row>
                  <Row>
                  <Col sm={6}>
                      <Form.Group controlId="subType">
                        <Form.Label>SubType</Form.Label>
                        <Form.Control as="select" name="subType">
                          <option>rivers-of-india</option>
                          <option>communities-and-livelihoods</option>
                          <option>music-art-performances</option>
                          <option>architecture-design</option>
                          <option>traditions-and-ritual-practices</option>
                          <option>ecology-and-nature</option>
                          <option>exhibitions</option>
                          <option>urban-waters</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="label">
                        <Form.Label>Label</Form.Label>
                        <Form.Control
                          type="text"
                          name="label"
                          placeholder="label"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col sm={12}>
                      <Form.Group controlId="brief">
                        <Form.Label>Brief</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="5"
                          type="text"
                          name="brief"
                          placeholder="brief"
                        />
                      </Form.Group>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col sm={12}>
                      <Form.Group controlId="desc">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          type="text"
                          name="de"
                          placeholder="description"
                          maxlength="1800"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId="author">
                        <Form.Label>Author</Form.Label>
                        <Form.Control
                          type="text"
                          name="author"
                          placeholder="author"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="locationName">
                        <Form.Label>Location Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="locationName"
                          placeholder="Location label"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId="latitude">
                        <Form.Label>Latitude</Form.Label>
                        <Form.Control
                          type="text"
                          name="lat"
                          placeholder="Latitude"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="longitude">
                        <Form.Label>Longitude</Form.Label>
                        <Form.Control
                          type="text"
                          name="lon"
                          placeholder="Longitude"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="tags-row">
                    <p className="tags-title">Tags</p>
                  <TagInput className="custom-tags" tags={this.state.tags} onTagsChanged={this.onTagsChanged} />
                  </Row>
                  <Form.Group>
                    <Row>
                      <Col sm={12}>
                        <div>
                          <input
                            className="fileInput"
                            type="file"
                            onChange={this.fileSelectedHandler}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4}>
                        <div
                          className="imgPreview"
                          style={{
                            border: '1px solid',
                            width: 'auto',
                            height: '220px',
                            margin: '10px',
                          }}
                        >
                          {$imagePreview}
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div
                          className="imgPreview"
                          style={{
                            border: '1px solid',
                            width: 'auto',
                            height: '220px',
                            margin: '10px',
                          }}
                        >
                          {$imagePreview}
                        </div>
                      </Col>

                      <Col sm={4}>
                        <div
                          className="imgPreview"
                          style={{
                            border: '1px solid',
                            width: 'auto',
                            height: '220px',
                            margin: '10px',
                          }}
                        >
                          {$imageCoverview}
                        </div>
                        <div>
                          <input
                            type="radio"
                            value="yes"
                            name="icon_class"
                            checked={this.state.selectedOption === 'yes'}
                            onChange={this.radioChange}
                          />
                          Yes
                          <input
                            type="radio"
                            value="no"
                            name="icon_class"
                            checked={this.state.selectedOption === 'no'}
                            onChange={this.radioChange}
                          />
                          No
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <Button onClick={this.fileUploadHandler}>
                          Upload Cover Image
                        </Button>
                        <Progress
                          max="100"
                          color="success"
                          value={this.state.loaded}
                        >
                          {Math.round(this.state.loaded, 2)}%
                        </Progress>
                      </Col>
                    </Row>
                  </Form.Group>
                  
                  <Row>
                    <Col sm={8}></Col>
                    <Col sm={4} className="text-align-r">
                      <Form.Group>
                        <Button 
                        type="submit">Add Stories</Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddStoriesModal;
