import React from "react";

class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = { textContent: "", textCaption: "" };
  }

  onChange = (evt) => {
    this.setState({
      textContent: evt.editor.getData(),
    });
  };

  onPChange = (evt) => {
    this.setState({
      textCaption: evt.editor.getData(),
    });
  };

  render() {
    console.log(this.state);
    return (
      <div className="col-sm-12 no-padding">
        <div className="row">
          <div className="mar-b-16 col-sm-6 no-l-padding">
            <h6>Type</h6>
            <input
              type="text"
              name="docType"
              placeholder="Enter Doc Type"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
          <div className="mar-b-16 col-sm-6">
            <h6>Author</h6>
            <input
              type="text"
              name="docAuthor"
              placeholder="Enter Doc Author"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
        </div>
        <div className="row">
          <div className="mar-b-16 col-sm-6">
            <h6>Year of Publishing</h6>
            <input
              type="text"
              name="docYear"
              placeholder="Enter Year"
              style={{ width: "100%", height: 48 }}
              maxlength="4"
            />
          </div>
          <div className="mar-b-16 col-sm-6">
            <h6>Publisher</h6>
            <input
              type="text"
              name="docPublisher"
              placeholder="Enter Doc Publisher"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
        </div>
        <div className="row">
          <div className="mar-b-16 col-sm-12">
            <h6>About</h6>
            <input
              type="textarea"
              name="docAbstract"
              placeholder="Enter Doc About"
              style={{ width: "100%", height: 48 }}
              maxlength="1300"
            />
          </div>
        </div>
      </div>
    );
  }
}
export default Documents;
