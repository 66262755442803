import React, {Component} from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faTrash,
  //faPlus,
  faFile,
} from '@fortawesome/free-solid-svg-icons';
//import {Button} from 'react-bootstrap';
import AddResourceModal from './AddResourceModal';
import EditResourceModal from './EditResourceModal';
import AddResourceData from './AddResourceData';

class Resources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addModalShow: false,
      editModalShow: false,
      resourceModalShow: false,
      isLoaded: false,
    };
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/resources/`)
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            data: result.resources,
          });
        },
        error => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  updateResources(cData) {
    this.setState({
      selectedCategory: cData,
    });
  }

  deleteResource(name) {
    if (window.confirm('Are you sure?')) {
      fetch(`${process.env.REACT_APP_API_URL}/resources/` + name, {
        method: 'DELETE',
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    }
  }

  editModalClose = () => {
    this.setState({editModalShow: !this.state.editModalShow});
  };

  addModalClose = () => this.setState({addModalShow: false});

  resourceModalClose = () => this.setState({resourceModalShow: false});

  render() {
    const {
      error,
      isLoaded,
      rPriority,
      rName,
      rType,
      rLabel,
      rIcon,
    } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
        <div className="categories-container loader"> 
        </div>
      );
    } else {
      return (
        <div className="categories-container">
          <div className="title-container">
            <div className="title-left-container">
              <h3> Resources </h3>
            </div>
            <div className="title-right-container">
              {/* <Button
                className="add-item-btn"
                variant="primary"
                onClick={() => this.setState({addModalShow: true})}
              >
                Add Resources
              </Button> */}
            </div>

            <AddResourceModal
              show={this.state.addModalShow}
              onHide={this.addModalClose}
            />
          </div>

          <ReactTable
            loading={false}
            className="custom-r-table-r"
            defaultPageSize={12}
            data={this.state.data}
            columns={[
              {
                Header: 'Name',
                accessor: 'name',
                width: 150,
              },
              // {
              //   Header: 'Type',
              //   accessor: 'type',
              //   width: 100,
              // },
              {
                Header: 'Label',
                accessor: 'label',
                width: 150,
              },
              {
                Header: 'Icon',
                Cell: row => {
                  return (
                    <div>
                      <img
                        height={64}
                        alt={row.original.icon}
                        src={row.original.icon}
                      />
                    </div>
                  );
                },
                width: 100,
              },
              // {
              //   Header: 'Priority',
              //   accessor: 'priority',
              //   width: 100,
              // },
              {
                Header: 'Action',
                accessor: 'Action',
              

                Cell: row => (
                  <div style={{justifyContent: 'center', display: 'flex'}}>
                    <div
                      className="action-icon"
                      title="Edit"
                      onClick={() => {
                        this.updateResources(row.original);
                        this.setState({
                          operation: 'Update',
                          selectedRow: row.original,
                          editModalShow: true,
                          rPriority: row.original.priority,
                          rName: row.original.name,
                          rLabel: row.original.label,
                          rType: row.original.type,
                          rIcon: row.original.icon,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </div>
                    {/* <div
                      className="action-icon add"
                      title="Add Resource Data"
                      onClick={() => {
                        this.updateResources(row.original);
                        this.setState({
                          operation: 'Add',
                          selectedRow: row.original,
                          resourceModalShow: true,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </div> */}
                    <div
                      className="action-icon preview"
                      title="Preview"
                      onClick={() => {
                        localStorage.setItem(
                          'name',
                          JSON.stringify(row.original.name)
                        );
                        window.location.href = '/resourcedatalist';
                        this.setState({
                          operation: 'Add',
                          selectedRow: row.original,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faFile} />
                    </div>
                    <div
                      className="action-icon delete"
                      title="Delete"
                      onClick={() => {
                        this.deleteResource(row.original.name);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </div>
                  </div>
                ),
              },
            ]}
          />
          <EditResourceModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            rPriority={rPriority}
            rName={rName}
            rType={rType}
            rLabel={rLabel}
            rIcon={rIcon}
          />

          <AddResourceData
            show={this.state.resourceModalShow}
            onHide={this.resourceModalClose}
          />
        </div>
      );
    }
  }
}

export default Resources;
