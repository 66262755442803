import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Logo from '../../assets/logo.png';
import {Button} from 'react-bootstrap';

class Landing extends Component {
  render() {
    return (
      <div style={{height: '75vh'}} className="container valign-wrapper">
        <div className="row">
          <div className="col s12 center-align" style={{marginTop: '25%'}}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <img src={Logo} alt="logo" style={{width: '250px'}} />
            </div>
            <br />
            <div className="row">
              <div
                className="col s6"
                style={{display: 'flex', justifyContent: 'center'}}
              >
                <Link
                  to="/register"
                  style={{
                    width: '140px',
                    borderRadius: '3px',
                    letterSpacing: '1.5px',
                  }}
                >
                  <Button>Register</Button>
                </Link>
              </div>
              <div
                className="col s6"
                style={{display: 'flex', justifyContent: 'center'}}
              >
                <Link
                  to="/login"
                  style={{
                    width: '140px',
                    borderRadius: '3px',
                    letterSpacing: '1.5px',
                    marginTop: '10px',
                  }}
                >
                  <Button>Log In</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Landing;
