import React from "react";

class Films extends React.Component {
  constructor(props) {
    super(props);
    this.state = { textContent: "", textCaption: "" };
  }

  onChange = (evt) => {
    this.setState({
      textContent: evt.editor.getData(),
    });
  };

  onPChange = (evt) => {
    this.setState({
      textCaption: evt.editor.getData(),
    });
  };

  render() {
    console.log(this.state);
    return (
      <div className="col-sm-12 no-padding">
        <div className="row">
          <div className="mar-b-16 col-sm-6 no-l-padding">
            <h6>Director</h6>
            <input
              type="text"
              name="filmsDirector"
              placeholder="Enter Films Director"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
          <div className="mar-b-16 col-sm-6">
            <h6>Producer</h6>
            <input
              type="text"
              name="filmsProducer"
              placeholder="Enter Films Producer"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
        </div>
        <div className="row">
          <div className="mar-b-16 col-sm-12">
            <h6>Year of Release</h6>
            <input
              type="text"
              name="filmsYear"
              placeholder="Enter Films Year of Release"
              style={{ width: "100%", height: 48 }}
              maxlength="4"
            />
          </div>
        </div>
        <div className="row">
          <div className="mar-b-16 col-sm-12">
            <h6>Abstract</h6>
            <input
              type="textarea"
              name="filmsAbstract"
              placeholder="Enter Films Abstract"
              style={{ width: "100%", height: 48 }}
              maxlength="1300"
            />
          </div>
        </div>
      </div>
    );
  }
}
export default Films;
