import React from "react";

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = { textContent: "", textCaption: "" };
  }

  onChange = (evt) => {
    this.setState({
      textContent: evt.editor.getData(),
    });
  };

  onPChange = (evt) => {
    this.setState({
      textCaption: evt.editor.getData(),
    });
  };

  render() {
    console.log(this.state);
    return (
      <div className="col-sm-12 no-padding">
        <div className="row">
          <div className="mar-b-16 col-sm-6 no-l-padding">
            <h6>Domain</h6>
            <input
              type="text"
              name="pDomain"
              placeholder="Enter Domain"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
          <div className="mar-b-16 col-sm-6">
            <h6>Collaborator</h6>
            <input
              type="text"
              name="pCollaborator"
              placeholder="Enter Collaborator"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
        </div>
        <div className="row">
          <div className="mar-b-16 col-sm-6">
            <h6>Facilitator</h6>
            <input
              type="text"
              name="pfacilitator"
              placeholder="Enter Facilitator"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
          <div className="mar-b-16 col-sm-6">
            <h6>Owner</h6>
            <input
              type="text"
              name="pOwner"
              placeholder="Enter Owner"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
        </div>
        <div className="row">
          <div className="mar-b-16 col-sm-12">
            <h6>Participants</h6>
            <input
              type="textarea"
              name="pParticipants"
              placeholder="Enter Participants"
              style={{ width: "100%", height: 48 }}
              maxlength="650"
            />
          </div>
        </div>
        <div className="row">
          <div className="mar-b-16 col-sm-12">
            <h6>Abstract</h6>
            <input
              type="textarea"
              name="pAbstract"
              placeholder="Enter Organisation About"
              style={{ width: "100%", height: 48 }}
              maxlength="1300"
            />
          </div>
        </div>
      </div>
    );
  }
}
export default Projects;
