import React, {Component} from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faTrash,
  faEye,
  faUpload,
  faFile,
} from '@fortawesome/free-solid-svg-icons';
import {Button} from 'react-bootstrap';
import AddStoriesModal from './AddStoriesModal';
import EditStoriesModal from './EditStoriesModal';
import AddStoryDataModal from './AddStoryDataModal';
//import ImageUpload from './ImageUpload';

class Stories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addModalShow: false,
      editModalShow: false,
      addDataModalShow: false,
      imageModalShow: false,
      id: props.data,
      data: [],
      isLoaded: false,
    };
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/stories`)
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            data: result.stories,
          });
        },
        error => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  updateStories(cData) {
    this.setState({
      selectedCategory: cData,
    });
  }
  updateStoryData(cData) {
    this.setState({
      selectedCategory: cData,
    });
  }
  uploadImage(cData) {
    this.setState({
      selectedCategory: cData,
    });
  }

  editstorydata(cData) {
    this.setState({
      selectedCategory: cData,
    });
  }

  deleteStories(id) {
    if (window.confirm('Are you sure?')) {
      const token = localStorage.jwtToken ? localStorage.jwtToken : '';
      fetch(`${process.env.REACT_APP_API_URL}/stories/` + id, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });
    }
  }

  publishStories(id) {
    const token = localStorage.jwtToken ? localStorage.jwtToken : '';
    fetch(`${process.env.REACT_APP_API_URL}/stories/publish/` + id, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
    });
    window.confirm(
      '"You are Publishing the Story to LWM Platform, Please confirm!"'
    );
    window.location.reload();
  }

  editModalClose = () => {
    this.setState({editModalShow: !this.state.editModalShow});
  };

  addModalClose = () => this.setState({addModalShow: false});

  addDataModalClose = () => {
    this.setState({addDataModalShow: !this.state.addDataModalShow});
  };

  imageModalClose = () => {
    this.setState({imageModalShow: !this.state.imageModalShow});
  };

  render() {
    const {
      error,
      isLoaded,
      sId,
      sName,
      sDe,
      sType,
      sSubtype,
      sLongitude,
      sLatitude,
      sAuthor,
      sTag,
      sBrief,
      sLabel,
      sIcon,
      sLocationName,
    } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="categories-container loader"></div>;
    } else {
      return (
        <div className="categories-container">
          <div className="title-container text-left row">
            <div className="title-left-container padding-l-15">
              <h3> Stories </h3>
            </div>
            <div className="title-right-container">
              <Button
                className="add-item-btn"
                variant="primary"
                onClick={() => this.setState({addModalShow: true})}
              >
                Add Stories
              </Button>
            </div>
            <AddStoriesModal
              show={this.state.addModalShow}
              onHide={this.addModalClose}
            />
          </div>

          <ReactTable
            loading={false}
            defaultPageSize={15}
            data={this.state.data}
            columns={[
              {
                Header: 'Label',
                accessor: 'label',
                style: {whiteSpace: 'unset'},
              },
              {
                Header: 'SubType',
                accessor: 'subType',
                style: {whiteSpace: 'unset'},
              },
              // {
              //   Header: 'Brief',
              //   accessor: 'brief',
              //   width: 150,
              //   style: {whiteSpace: 'unset', height: 100},
              // },
              {
                Header: 'Descrption',
                accessor: 'de',
                width: 150,
                style: {whiteSpace: 'unset', height: 100},
              },
              {
                Header: 'Author',
                accessor: 'author',
                width: 200,
                height: 100,
              },
              {
                Header: 'cover',
                Cell: row => {
                  return (
                    <div>
                      <img
                        height={100}
                        alt={row.original.cover}
                        src={row.original.cover}
                      />
                    </div>
                  );
                },
              },
              {
                Header: 'Status',
                accessor: 'status',
              },
              {
                Header: 'Action',
                accessor: 'Action',

                Cell: row => (
                  <div className="story-action">
                    <div
                      className="action-icon"
                      title="Edit"
                      onClick={() => {
                        console.log("printing row.original");
                        console.log(row.original);
                        this.updateStories(row.original);
                        this.setState({
                          operation: 'Update',
                          selectedRow: row.original,
                          editModalShow: true,
                          sId: row.original.id,
                          sName: row.original.name,
                          sLabel: row.original.label,
                          sDe: row.original.de,
                          sType: row.original.type,
                          sSubtype: row.original.subType,
                          sLongitude: row.original.location.lon,
                          sLatitude: row.original.location.lat,
                          sAuthor: row.original.author,
                          sBrief: row.original.brief,
                          sIcon: row.original.cover,
                          sTag : row.original.tags,
                          sLocationName: row.original.location.locationName,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </div>
                    <div
                      className="action-icon preview"
                      title="story Data"
                      onClick={() => {
                        localStorage.setItem(
                          'id',
                          JSON.stringify(row.original.id)
                        );
                        window.location.href = '/storydatalist';
                        this.setState({
                          operation: 'Add',
                          selectedRow: row.original,
                          sId: row.original.id,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faFile} />
                    </div>
                    <div
                      className="action-icon preview"
                      title="Preview"
                      onClick={event =>
                        window.open(
                          `https://livingwatersmuseum.org/${row.original.name}`,
                          '_blank'
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </div>

                    <div
                      className="action-icon publish"
                      title="Publish"
                      onClick={() => {
                        this.publishStories(row.original.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faUpload} />
                    </div>
                    <div
                      className="action-icon delete"
                      title="Delete"
                      onClick={() => {
                        this.deleteStories(row.original.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </div>
                  </div>
                ),
                style: {whiteSpace: 'unset'},
              },
            ]}
          />
          <EditStoriesModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            sId={sId}
            sName={sName}
            sDe={sDe}
            sType={sType}
            sSubtype={sSubtype}
            sLongitude={sLongitude}
            sLatitude={sLatitude}
            sAuthor={sAuthor}
            sBrief={sBrief}
            sLabel={sLabel}
            sIcon={sIcon}
            sTag = {sTag}
            sLocationName={sLocationName}
          />
          <AddStoryDataModal
            show={this.state.addDataModalShow}
            onHide={this.addDataModalClose}
            sId={sId}
          />

          {/* <ImageUpload
            show={this.state.imageModalShow}
            onHide={this.imageModalClose}
            sId={sId}
          /> */}
        </div>
      );
    }
  }
}

export default Stories;
