import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './Navbar.css';

class Navbar extends Component {
  render() {
    return (
      <div className="sidenav-1">
        
        <Link
          to="/dashboard"
          style={{
            fontFamily: 'monospace',
          }}
          className=""
        >
          Home
        </Link>

        <Link
          to="/categories"
          style={{
            fontFamily: 'monospace',
          }}
          className=""
        >
          Categories
        </Link>
        <Link
          to="/resources"
          style={{
            fontFamily: 'monospace',
          }}
          className=""
        >
          Resources
        </Link>
        <Link
          to="/resourcealllist"
          style={{
            fontFamily: 'monospace',
          }}
          className=""
        >
          Resource List
        </Link>
        <Link
          to="/stories"
          style={{
            fontFamily: 'monospace',
          }}
          className=""
        >
          Stories
        </Link>
        {/* <Link
          to="/add-data"
          style={{
            fontFamily: 'monospace',
          }}
          className=""
        >
          Add Data
        </Link>  */}
           </div>
    );
  }
}

export default Navbar;
