/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import { Progress } from "reactstrap";
import CKEditor from "react-ckeditor-component";

const options = [
  { value: "text", label: "Text" },
  { value: "img", label: "Image" },
  { value: "video", label: "Video" },
  { value: "audio", label: "Audio" },
  { value: "3d", label: "3D" },
  { value: "vr", label: "VR" },
];

class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      selectedFile: null,
      imageCaption: "",
      enforceFocus: false,
    };
  }

  fileSelectedHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  fileUploadHandler = () => {
    const fd1 = new FormData();
    fd1.append("file", this.state.selectedFile);
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/upload`, fd1, {
        onUploadProgress: (ProgressEvent) => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
          });
        },
      })
      .then((res) => {
        this.props.image(res.data.path);
        this.setState({ image: res.data.path });
      });
  };

  onIChange = (evt) => {
    this.setState({
      imageCaption: evt.editor.getData(),
    });
  };

  render() {
    return (
      <div>
        <div className="mar-b-16">
          <h6>Title</h6>
          <input
            type="text"
            name="title"
            placeholder="title"
            style={{ width: "58%" }}
            maxlength="150"
          />
        </div>
        <div className="mar-b-16">
          <h6>Image Upload</h6>
          <div>
            <input
              type="file"
              id="dataImg"
              name={this.state.image}
              onChange={this.fileSelectedHandler}
              placeholder="image"
            />
          </div>
          <div style={{ margin: 10 }}>
            <Progress max="100" color="success" value={this.state.loaded}>
              {Math.round(this.state.loaded, 2)}%
            </Progress>
          </div>
          <Button onClick={this.fileUploadHandler}>Upload Image</Button>
        </div>
        <div className="mar-b-16">
          <textarea
            rows="6"
            cols="50"
            type="text"
            name="imageCaption"
            maxlength="500"
            placeholder="Write You Content Here"
            value={this.state.imageCaption}
            defaultValue={this.state.imageCaption}
            style={{ display: "none", height: 58 }}
          />
          <h6>Caption</h6>
          <CKEditor
            activeClass="p10"
            name="caption"
            // name="textCaption"
            content={this.state.imageCaption}
            events={{
              change: this.onIChange,
            }}
          />
        </div>
      </div>
    );
  }
}

class Text extends React.Component {
  constructor(props) {
    super(props);
    this.state = { textContent: "", textCaption: "" };
  }

  onChange = (evt) => {
    this.setState({
      textContent: evt.editor.getData(),
    });
  };

  onPChange = (evt) => {
    this.setState({
      textCaption: evt.editor.getData(),
    });
  };

  render() {
    console.log(this.state);
    return (
      <div>
        <div className="mar-b-16">
          <h6>Title</h6>
          <input
            type="text"
            name="title"
            placeholder="title"
            style={{ width: "58%", height: 58 }}
            maxlength="150"
          />
        </div>
        <div className="mar-b-16">
          <h6>Write You Content Here</h6>
          <textarea
            rows="6"
            cols="50"
            type="text"
            name="textContent"
            maxlength="500"
            placeholder="Write You Content Here"
            value={this.state.textContent}
            defaultValue={this.state.textContent}
            style={{ display: "none", height: 58 }}
          />
          <CKEditor
            activeClass="p10"
            name="cont"
            // name="textContent"
            id="textContent"
            content={this.state.textContent}
            events={{
              change: this.onChange,
            }}
          />
        </div>
        <div className="mar-b-16">
          <textarea
            rows="6"
            cols="50"
            type="text"
            name="textCaption"
            maxlength="500"
            placeholder="Write You Content Here"
            value={this.state.textCaption}
            defaultValue={this.state.textCaption}
            style={{ display: "none", height: 58 }}
          />
          <h6>Caption</h6>
          <CKEditor
            activeClass="p10"
            name="caption"
            // name="textCaption"
            content={this.state.textCaption}
            events={{
              change: this.onPChange,
            }}
          />
        </div>
      </div>
    );
  }
}

class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = { content: "", videoCaption: "" };
  }

  onVChange = (evt) => {
    this.setState({
      videoCaption: evt.editor.getData(),
    });
  };

  render() {
    return (
      <div>
        <div className="mar-b-16">
          <h6>Title</h6>
          <input
            type="text"
            name="title"
            placeholder="title"
            style={{ width: "58%", height: 58 }}
            maxlength="150"
          />
        </div>

        <div className="mar-b-16">
          <label>Add Video URL</label>
          <input type="text" name="videourl" placeholder="text" required />
        </div>
        <div className="mar-b-16">
          <textarea
            rows="6"
            cols="50"
            type="text"
            name="videoCaption"
            maxlength="500"
            placeholder="Write You Content Here"
            value={this.state.videoCaption}
            defaultValue={this.state.videoCaption}
            style={{ display: "none", height: 58 }}
          />
          <h6>Caption</h6>
          <CKEditor
            activeClass="p10"
            name="caption"
            // name="textCaption"
            content={this.state.videoCaption}
            events={{
              change: this.onVChange,
            }}
          />
        </div>
      </div>
    );
  }
}

class Audio extends React.Component {
  constructor(props) {
    super(props);
    this.state = { content: "", audioCaption: "" };
  }

  onVChange = (evt) => {
    this.setState({
      audioCaption: evt.editor.getData(),
    });
  };

  render() {
    return (
      <div>
        <div className="mar-b-16">
          <h6>Title</h6>
          <input
            type="text"
            name="title"
            placeholder="title"
            style={{ width: "58%", height: 58 }}
            maxlength="150"
          />
        </div>

        <div className="mar-b-16">
        <h6>Audio Iframe Data</h6>
          <textarea
            rows="6"
            cols="50"
            type="text"
            name="audio"
            placeholder="put your iframe here from https://soundcloud.com/"
            required
          />
        </div>
        <div className="mar-b-16">
          <textarea
            rows="6"
            cols="50"
            type="text"
            name="audioCaption"
            maxlength="500"
            placeholder="Write You Content Here"
            value={this.state.audioCaption}
            defaultValue={this.state.audioCaption}
            style={{ display: "none", height: 58 }}
          />
          <h6>Caption</h6>
          <CKEditor
            activeClass="p10"
            name="caption"
            // name="audioCaption"
            content={this.state.audioCaption}
            events={{
              change: this.onVChange,
            }}
          />
        </div>
      </div>
    );
  }
}

class ThreeD extends React.Component {
  constructor(props) {
    super(props);
    this.state = { content: "", dCaption: "" };
  }

  onVChange = (evt) => {
    this.setState({
      dCaption: evt.editor.getData(),
    });
  };

  render() {
    return (
      <div>
        <div className="mar-b-16">
          <h6>Title</h6>
          <input
            type="text"
            name="title"
            placeholder="title"
            style={{ width: "58%", height: 58 }}
            maxlength="150"
          />
        </div>

        <div className="mar-b-16">
          <h6>3D</h6>
          <textarea
            rows="6"
            cols="50"
            type="text"
            name="threed"
            placeholder="put your iframe here from https://sketchfab.com/"
            required
          />
        </div>
        <div className="mar-b-16">
          <textarea
            rows="6"
            cols="50"
            type="text"
            name="dCaption"
            maxlength="500"
            placeholder="Write You Content Here"
            value={this.state.dCaption}
            defaultValue={this.state.dCaption}
            style={{ display: "none", height: 58 }}
          />
          <h6>Caption</h6>
          <CKEditor
            activeClass="p10"
            name="caption"
            // name="dCaption"
            content={this.state.dCaption}
            events={{
              change: this.onVChange,
            }}
          />
        </div>
      </div>
    );
  }
}

class VR extends React.Component {
  constructor(props) {
    super(props);
    this.state = { content: "", vrCaption: "" };
  }

  onVChange = (evt) => {
    this.setState({
      vrCaption: evt.editor.getData(),
    });
  };

  render() {
    return (
      <div>
        <div className="mar-b-16">
          <h6>Title</h6>
          <input
            type="text"
            name="title"
            placeholder="title"
            style={{ width: "58%", height: 58 }}
            maxlength="150"
          />
        </div>

        <div className="mar-b-16">
          <label>VR Iframe</label>
          <textarea
            rows="6"
            cols="50"
            type="text"
            name="vr"
            placeholder="put your iframe here"
            required
          />
        </div>
        <div className="mar-b-16">
          <textarea
            rows="6"
            cols="50"
            type="text"
            name="vrCaption"
            maxlength="500"
            placeholder="Write You Content Here"
            value={this.state.vrCaption}
            defaultValue={this.state.vrCaption}
            style={{ display: "none", height: 58 }}
          />
          <h6>Caption</h6>
          <CKEditor
            activeClass="p10"
            name="caption"
            //name="vrCaption"
            content={this.state.vrCaption}
            events={{
              change: this.onVChange,
            }}
          />
        </div>
      </div>
    );
  }
}

class AddStoryDataModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.data,
      image: "",
      selectedOption: props.sType,
      enforceFocus: false,
      order: props.data,
    };
    this.onChange = this.onChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onChange = (e) => {
    this.set({ [e.target.name]: e.target.value });
    console.log([e.target.value]);
  };

  onChange1 = (evt) => {
    var newContent = evt.editor.getData();
    this.setState({
      data: newContent,
    });
    // console.log(newContent)
  };

  handleImage = (image) => {
    console.log(image);
    this.setState({
      image: image,
    });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    console.log("Option selected: ", selectedOption);
  };

  handleSubmit = (event) => {
    const token = localStorage.jwtToken ? localStorage.jwtToken : '';
    if (this.state.selectedOption.value === "text") {
      fetch(
        `${process.env.REACT_APP_API_URL}/stories/block/${this.props.sId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            'x-access-token': token,
          },
          body: JSON.stringify({
            type: this.state.selectedOption.value,
            content: event.target.textContent.value,
            title: event.target.title.value,
            caption: event.target.textCaption.value,
          }),
        }
      ).then((res) => res.json());
      window.confirm(
        '"You are editing a story data to LWM Platform, Please confirm!"'
      );
      window.location.reload();
    }
    if (this.state.selectedOption.value === "img") {
      fetch(
        `${process.env.REACT_APP_API_URL}/stories/block/${this.props.sId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            'x-access-token': token,
          },
          body: JSON.stringify({
            type: this.state.selectedOption.value,
            url: this.state.image,
            caption: event.target.imageCaption.value,
            title: event.target.title.value,
          }),
        }
      ).then((res) => res.json());
      event.preventDefault();
      window.confirm(
        '"You are editing a story data to LWM Platform, Please confirm!"'
      );
      window.location.reload();
    }
    if (this.state.selectedOption.value === "audio") {
      fetch(
        `${process.env.REACT_APP_API_URL}/stories/block/${this.props.sId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            'x-access-token': token,
          },
          body: JSON.stringify({
            type: this.state.selectedOption.value,
            audio: event.target.audio.value,
            caption: event.target.audioCaption.value,
            title: event.target.title.value,
          }),
        }
      ).then((res) => res.json());
      event.preventDefault();
      window.confirm(
        '"You are editing a story data to LWM Platform, Please confirm!"'
      );
      window.location.reload();
    }
    if (this.state.selectedOption.value === "3d") {
      fetch(
        `${process.env.REACT_APP_API_URL}/stories/block/${this.props.sId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            'x-access-token': token,
          },
          body: JSON.stringify({
            type: this.state.selectedOption.value,
            threed: event.target.threed.value,
            caption: event.target.dCaption.value,
            title: event.target.title.value,
          }),
        }
      ).then((res) => res.json());
      event.preventDefault();
      window.confirm(
        '"You are editing a story data to LWM Platform, Please confirm!"'
      );
      window.location.reload();
    }
    if (this.state.selectedOption.value === "vr") {
      fetch(
        `${process.env.REACT_APP_API_URL}/stories/block/${this.props.sId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            'x-access-token': token,
          },
          body: JSON.stringify({
            type: this.state.selectedOption.value,
            vr: event.target.vr.value,
            caption: event.target.vrCaption.value,
            title: event.target.title.value,
          }),
        }
      ).then((res) => res.json());
      event.preventDefault();
      window.confirm(
        '"You are editing a story data to LWM Platform, Please confirm!"'
      );
      window.location.reload();
    } else if( 'undefined' !== event.target.videourl && event.target.videourl ) {
      let videoTempUrl = event.target.videourl.value;
      videoTempUrl = videoTempUrl.replace("/watch?v=", "/embed/");
      fetch(
        `${process.env.REACT_APP_API_URL}/stories/block/${this.props.sId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            'x-access-token': token,
          },
          body: JSON.stringify({
            type: this.state.selectedOption.value,
            videourl: videoTempUrl,
            title: event.target.title.value,
            caption: event.target.videoCaption.value,
          }),
        }
      ).then((res) => res.json());
      event.preventDefault();
      window.confirm(
        '"You are uploading a new story data to LWM Platform, Please confirm!"'
      );
      window.location.reload();
    }
  };

  render() {
    const { selectedOption } = this.state;
    return (
      <Modal
        {...this.props}
        size="lg"
        enforceFocus={this.state.enforceFocus}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Story Data
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container">
            <Row>
              <Col sm={12}>
                <Form onSubmit={this.handleSubmit}>
                  <Form.Group controlId="id">
                    <Form.Label>Id</Form.Label>
                    <Form.Control
                      type="number"
                      name="id"
                      required
                      disabled
                      placeholder="id"
                      defaultValue={this.props.sId}
                    />
                  </Form.Group>
                  <Form.Group controlId="type">
                    <Form.Label>Type</Form.Label>
                    <div>
                      <Select
                        value={selectedOption}
                        onChange={this.handleChange}
                        options={options}
                        placeholder="Select Type"
                        isSearchable={options}
                      />
                    </div>
                  </Form.Group>
                  <div>
                    {selectedOption && selectedOption.value === "img" ? (
                      <Image image={this.handleImage} />
                    ) : selectedOption && selectedOption.value === "text" ? (
                      <Text data={this.state.data} onChange={this.onChange1} />
                    ) : selectedOption && selectedOption.value === "audio" ? (
                      <Audio />
                    ) : selectedOption && selectedOption.value === "3d" ? (
                      <ThreeD />
                    ) : selectedOption && selectedOption.value === "vr" ? (
                      <VR />
                    ) : selectedOption && selectedOption.value === "video" ? (
                      <Video />
                    ) : null}
                  </div>
                  <Form.Group>
                    <Button type="submit">Add Data</Button>
                  </Form.Group>
                  <Form.Group>
                    <p>{this.state.content}</p>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddStoryDataModal;
