import React from "react";

class AudioVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { textContent: "", textCaption: "" };
  }

  onChange = (evt) => {
    this.setState({
      textContent: evt.editor.getData(),
    });
  };

  onPChange = (evt) => {
    this.setState({
      textCaption: evt.editor.getData(),
    });
  };

  render() {
    console.log(this.state);
    return (
      <div className="col-sm-12 no-padding">
        <div className="row">
          <div className="mar-b-16 col-sm-6 no-l-padding">
            <h6>Type</h6>
            <input
              type="text"
              name="avType"
              placeholder="Enter AudioVideo Type"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
          <div className="mar-b-16 col-sm-6">
            <h6>Creator</h6>
            <input
              type="text"
              name="avCreator"
              placeholder="Enter AudioVideo Creator"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
        </div>
        <div className="row">
          <div className="mar-b-16 col-sm-6">
            <h6>Supporter</h6>
            <input
              type="text"
              name="avSupporter"
              placeholder="Enter AudioVideo Supporter"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
          <div className="mar-b-16 col-sm-6">
            <h6>Publisher</h6>
            <input
              type="text"
              name="avPublisher"
              placeholder="Enter AudioVideo Publisher"
              style={{ width: "100%", height: 48 }}
              maxlength="100"
            />
          </div>
        </div>
        <div className="row">
          <div className="mar-b-16 col-sm-12">
            <h6>Abstract</h6>
            <input
              type="textarea"
              name="avAbstract"
              placeholder="Enter AudioVideo Abstract"
              style={{ width: "100%", height: 48 }}
              maxlength="1300"
            />
          </div>
        </div>
      </div>
    );
  }
}
export default AudioVideo;
