import React, {Component} from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faEye} from '@fortawesome/free-solid-svg-icons';
import AddDataModal from './AddDataModal';
// import DataTables from './DataTables';

class AddStoryData extends Component {
  constructor(props) {
    super(props);
    this.state = {addModalShow: false, viewModalShow: false};
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/stories`)
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            data: result.stories,
          });
        },
        error => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  updateCategory(cData) {
    this.setState({
      selectedCategory: cData,
    });
  }

  deleteStories(id) {
    if (window.confirm('Are you sure?')) {
      fetch(`${process.env.REACT_APP_API_URL}/stories/` + id, {
        method: 'DELETE',
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    }
  }

  viewModalClose = () => {
    this.setState({viewModalShow: !this.state.viewModalShow});
  };
  addModalClose = () => this.setState({addModalShow: false});

  render() {
    const {sId} = this.state;
    return (
      <div
        style={{
          justifyContent: 'center',
          display: 'block',
          marginLeft: '220px',
        }}
      >
        <div>
          <ReactTable
            loading={false}
            defaultPageSize={10}
            data={this.state.data}
            columns={[
              {
                Header: 'ID',
                accessor: 'id',
              },
              {
                Header: 'Name',
                accessor: 'name',
              },
              {
                Header: 'Type',
                accessor: 'type',
              },
              {
                Header: 'SubType',
                accessor: 'subType',
              },
              {
                Header: 'Label',
                accessor: 'label',
              },
              {
                Header: 'Action',
                accessor: 'Action',

                Cell: row => (
                  <div style={{justifyContent: 'center', display: 'flex'}}>
                    <div
                      style={{
                        width: 30,
                        padding: 2,
                        color: 'white',
                        background: '#287bff',
                        textAlign: 'center',
                        borderRadius: 5,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        this.updateCategory(row.original);
                        this.setState({
                          operation: 'Update',
                          selectedRow: row.original,
                          addModalShow: true,
                          sId: row.original.id,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </div>
                    <div
                      style={{
                        width: 30,
                        padding: 2,
                        color: 'white',
                        background: 'red',
                        textAlign: 'center',
                        borderRadius: 5,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        this.setState({
                          selectedRow: row.original,
                          viewModalShow: true,
                          sId: row.original.id,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </div>
                  </div>
                ),
              },
            ]}
          />

          <AddDataModal
            show={this.state.addModalShow}
            onHide={this.addModalClose}
            sId={sId}
          />
        </div>
        {/* <div>
        <DataTables />
        </div> */}
      </div>
    );
  }
}

export default AddStoryData;
